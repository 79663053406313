<template>
  <div>
    <v-card flat class="pa-0">
      <v-card-text class="pa-1">
        <v-row class="mx-0">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="6" md="5" lg="4">
            <v-card class="pa-1 text-center headline" outlined tile>
              {{ currentDay }} {{ currentDate }}
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <!-- <v-row class="mx-0">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="text-center headline">
            <v-fade-transition leave-absolute>
              <span :key="time">
                <span>{{ time }}</span>
              </span>
            </v-fade-transition>
          </v-col>
          <v-spacer></v-spacer>
        </v-row> -->
        <v-row class="mx-0 mb-5 mt-3 text-center">
            <v-col cols="12" align="center" class="pa-0">
              <template v-if="!timerData.isTimeRunning">
                <v-badge bordered color="success" icon="mdi-play" overlap class="mr-5">
                  <v-btn :disabled="!timerObj.shift_id ? true : (disableButton ? true : false)" :color="isDisabledTimer || isWeekLocked ? '' : 'success'" :class="isDisabledTimer || isWeekLocked ? 'include_stamp_attachment' : ''" tile @click="start()" class="mr-2">{{$t('message.timer.start')}}</v-btn><!-- :disabled="isDisabledTimer" -->
                </v-badge>
              </template>
              <template v-else>
                <v-badge bordered :color="holdTime ? 'orange' : 'success'" icon="mdi-stop" overlap class="mx-2 mr-5">
                  <v-btn tile :color="holdTime ? 'orange' : 'success'" dark @click="stopTimer" class="mr-2" :disabled="buttonDissable"><span>{{ time }}</span></v-btn>
                </v-badge>
              </template>
              <template>
                <input ref="uploader" class="d-none" type="file" accept="image/*" @change="onFileChanged">
              </template>
              <template v-if="timerData.isTimeRunning">
                <v-tooltip bottom>
                  <template template v-slot:activator="{ on }">
                    <v-btn class="mx-1" v-on="on" fab dark small color="error" @click="showCancelDialog = true">
                      <v-icon dark>mdi-cancel</v-icon>
                    </v-btn>
                   </template>
                  <span>{{$t('message.timer.cancelTimer')}}</span>
                </v-tooltip>
              </template>
              <template v-for="(item, index) in quickNavItems">
                <v-tooltip bottom :key="`${index}_navItem`">
                  <template template v-slot:activator="{ on }">
                    <v-btn class="mx-1" v-on="on" fab dark small :color="item.color" @click="$router.push(item.to)">
                      <v-icon dark>{{item.icon}}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t(item.tooltipText) }}</span>
                </v-tooltip>
              </template>
              <template>
                <v-tooltip bottom :key="`shift_help_navItem`">
                  <template template v-slot:activator="{ on }">
                    <v-btn class="mx-1" v-on="on" fab dark small color="primary" >
                      <v-icon dark>mdi-information</v-icon>
                    </v-btn>
                  </template>
                    <span v-if="this.$i18n.locale == 'en'">How Stemp works?
                        <ul> <li>Both stemp and Manual hour entry will work based on the shift, where shift is mandatory.</li>
                        <li>Shift is userdefined in Manage hours</li>
                        <li>If an shift is between 9:00 to 18:00, totally 9 hrs, if user starts at 9 it continue untill 18:00, at 18:00, the user will get an alert to stop or continue, if user wishes he can continue.</li>
                        <li>since shift is 9hrs, where he can start stemp at any time. For example, if user starts at 11:00  instead of 9:00, the stemp will alert user at 20:00(calculating 9 hrs)</li>
                        <li>The stemp can be stopped in between too as per the user desired.</li>
                        <li>If user fails to stempout at shift, it automatically stops at 24hrs and there will be alert to the user on their next login.</li>
                        <li>The 24hrs will include (project hours + stemphours +Pausetime + Absence hours) per day.</li>
                        <li>If an stemp includes attachments, it will not logout automatically at 24hrs, instead an alert will arose to include attachment on their next login.</li></ul>
                  </span>
                  <span v-else>
                      Hvordan virker Stemp?
                    <ul>
                      <li>Både stemp og Manuell timeregistrering vil fungere basert på skiftet, hvor skift er obligatorisk.</li>
                      <li>Skift er brukerdefinert i Administrer timer</li>
                      <li>Hvis et skift er mellom 9:00 og 18:00, totalt 9 timer, hvis brukeren starter kl. 9, fortsetter det til kl. 18:00, kl. han kan fortsette.</li>
                      <li>siden skiftet er 9 timer, hvor han kan starte stemp når som helst. for eksempel, hvis brukeren starter kl. 11.00 i stedet for kl. 9.00, vil stemplet varsle brukeren kl. 20.00 (beregner 9 timer)</li>
                      <li>Stemplet kan stoppes i mellom også etter brukerens bekvemmelighet.</li>
                      <li>Hvis brukeren ikke klarer å stoppe ved skiftet, stopper den automatisk ved 24 timer, og brukeren blir varslet ved neste pålogging.</li>
                      <li>De 24 timer vil inkludere (prosjekt timer + stemphours + pausetid + fravær timer) per dag.</li>
                      <li>Hvis en stemp inkluderer vedlegg, vil den ikke logge ut automatisk etter 24 timer, i stedet vil det oppstå et varsel om å inkludere vedlegg ved neste pålogging</li>
                    </ul>
                  </span>
                </v-tooltip>
              </template>
            </v-col>
          <!-- <v-spacer></v-spacer>
          <v-col cols="12" sm="6" xs="12" md="5" lg="4" align="center">
            <v-btn  color="info" tile dark @click="$router.push('/calendar')" class="mx-1">{{$t('message.timer.ga_til_time')}}</v-btn>
            <v-btn  color="primary" tile dark @click="$router.push('/myshifts')" class="mx-1">{{$t('message.shifts.shift')}}</v-btn>
          </v-col> -->
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="px-0 py-1">
            <v-form ref="shiftForm">
              <v-autocomplete @change="shiftChange()" :label="$t('message.timer.shift')" v-model="timerObj.shift_id" clearable :items="shifts" :rules="shiftRules" :item-text="a => $i18n.locale === 'en' ? a.en_name : a.no_name" item-value="id" dense hide-details outlined>
                <template slot="selection" slot-scope="data">
                    {{ $i18n.locale === 'en' ? data.item.en_name : data.item.no_name }}
                  </template>
              </v-autocomplete>
            </v-form>
             <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <span><p class="caption mx-2 my-0"> {{ $t('message.common.stempHint') }}</p></span>
              </v-tooltip>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="mx-0">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="pa-0">
            <form-data :references.sync="formReferences" :model="timerObj" ref="hourReference"></form-data>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="mx-0 mt-2">
          <v-flex class="xs4 xm3 lg2 pa-1" v-for="image in allAttachments" :key="image.id">
            <v-img width="100" height="50" :src="`${getBaseUrl}/Resources/${image.fileguid}`" @click="selectedImage = `${getBaseUrl}/Resources/${image.fileguid}`; showImageInFullScreen = true"></v-img>
          </v-flex>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showImageInFullScreen">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn fab elevation="0" class="white">
            <v-icon large @click="showImageInFullScreen = false">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-img :src="selectedImage"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DELETE WARNING -->
    <v-dialog v-model="showCancelDialog" persistent max-width="400px" class="mt-0">
      <v-card class="pa-0" flat>
        <v-card-title>
          {{ $t('message.common.confirmCancel') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          {{ $t('message.common.areYouSureCancel') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" id="confirmCancel" :loading="cancelLoading" @click="confirmCancel">{{ $t('message.common.yes') }}</v-btn>
          <v-btn color="primary" id="closeCancel" @click="showCancelDialog=false">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETE WARNING  -->
    <!-- Shift end popup -->
    <v-dialog persistent v-model="shiftEndDialog" width="500">
      <v-card>
        <v-card-title>{{ $t('message.common.confirmAddHour') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 font-weight-bold text-h6">
          {{ $t('message.common.doYouWantContinue') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="success" @click="continueExceeding()">{{ $t('message.shifts.continue') }}</v-btn>
          <v-btn class="error" @click="popupStopTimer()">{{ $t('message.timer.stop') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Exceed Validaion -->
    <v-dialog width="500" v-model="exeedValidaion" persistent>
      <v-card>
        <v-card-title> {{ $t('message.common.reachedHours') }} </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 text-h6">
         {{ $t('message.common.on') }} {{ exeedDate }} {{ $t('message.common.hoursExeed') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="exeedValidaion=false">{{ $t('message.common.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import TimerMixin from '../../mixins/timer'
import projectMixin from '@/mixins/projectMixin'
export default {
  mixins: [TimerMixin, projectMixin],
  props: ['test', 'shiftTime'],
  data () {
    return {
      currentDay: moment().format('dddd'),
      currentDate: moment().format('DD.MM.YYYY'),
      time: '00:00:00',
      webWorkerInstance: null,
      timerObj: {
        project_id: 0
      },
      listOfActivities: [],
      isInitital: true,
      // getCurrentProjects: [],
      showCancelDialog: false,
      cancelLoading: false,
      listOfbreaks: [{ val: 0 }, { val: 15 }, { val: 30 }, { val: 45 }, { val: 60 }],
      quickNavItems: [
        { icon: 'mdi-calendar-clock', to: '/calendar', tooltipText: 'message.timer.ga_til_time', color: 'info' },
        { icon: 'mdi-calendar-text-outline', to: '/myshifts', tooltipText: 'message.shifts.shift', color: 'primary' }
      ],
      isPictureTakenBefore: false,
      allAttachments: [],
      fileUploadType: 0, // 1-on start , 2- on stop,  3- on store
      selectedImage: '',
      showImageInFullScreen: false,
      reloadApp: false,
      holdTime: false,
      stempDate: null,
      shifts: [],
      startTime: null,
      shiftEndDialog: false,
      continueTime: true,
      yesContinueTime: false,
      currentDateTotal: null,
      currentDateTotalRunningHours: null,
      disableButton: false,
      exeedValidaion: false,
      exeedDate: null,
      startDatetotalHours: false,
      afterSaveRecord: {},
      buttonDissable: false,
      shiftRules: [v => !!v || 'Name is required']
    }
  },
  components: {
    'form-data': () => import('@/components/FormTemplate')
  },
  computed: {
    ...mapGetters(['getuserDetails', 'formType', 'getWorktypes', 'timerData', 'generalSettings', 'hasStampAttachments', 'getCurrentProjects']),
    formReferences () {
      return {
        cardFlat: false,
        properties: [{
          model: 'project_id',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$_requiredValidation,
          items: this.getCurrentProjects,
          select_text: (item) => this.setProjectname(item),
          select_value: 'id',
          label: this.$t('message.layout.projects'),
          class: 'xs12',
          is_show: this.$store.state.common.isProjectExcluded ? this.setDefaultProject() : true,
          disabled: this.isDisabledTimer || this.isWeekLocked
        },
        {
          model: 'break_hr',
          type: this.formType.SELECT,
          rules: [], // this.$_requiredValidation,
          items: this.listOfbreaks,
          select_text: (item) => item.val + ` ${this.$t('message.timer.minutes')}`,
          select_value: 'val',
          disabled: this.isDisabledTimer || this.isWeekLocked,
          label: this.$t('message.timer.pause'),
          class: 'xs12',
          // is_show: this.getPauseSettingType === 3,
          change: this.saveItem
        },
        {
          model: 'activity_id',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$store.state.common.isProjectExcluded ? [] : this.$_requiredValidation,
          items: this.listOfActivities,
          is_list: false,
          click: (search) => false,
          term: '',
          change: this.saveItem,
          select_text: 'name',
          select_value: 'id',
          label: this.$t('message.timer.activity'),
          class: 'xs12',
          disabled: this.isDisabledTimer || this.isWeekLocked
        }, {
          model: 'work_id',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$_requiredValidation,
          items: this.getWorktypes.filter(x => x.is_billable),
          is_list: false,
          click: (search) => false,
          term: '',
          change: this.saveItem,
          select_text: (val) => `${val[(this.$i18n.locale === 'en' ? 'en_name' : 'no_name')]}`,
          select_value: 'id',
          label: this.$t('message.common.worktype'),
          class: 'xs12',
          disabled: this.isDisabledTimer || this.isWeekLocked
        }, {
          model: 'location',
          type: this.formType.TEXT,
          rules: [],
          change: this.saveItem,
          label: this.$t('message.timer.place'),
          class: 'xs12',
          disabled: this.isDisabledTimer || this.isWeekLocked
        }, {
          model: 'comments',
          type: this.formType.TEXTAREA,
          rules: this.$_requiredValidation,
          change: this.saveItem,
          label: this.$t('message.common.comments'),
          class: 'xs12',
          rows: 3,
          disabled: this.isDisabledTimer || this.isWeekLocked
        }, {
          model: 'files',
          type: this.formType.FILES,
          rules: [],
          accept: 'image/*',
          change: this.storeFileChange,
          label: this.$t('message.deviation.upload_files'),
          class: 'xs12',
          is_show: this.hasStampAttachments,
          disabled: this.isDisabledTimer || this.isWeekLocked
        }],
        buttons: []
      }
    },
    getPauseSettingType () {
      return this.generalSettings.pause_setting_type
    },
    isDisabledTimer () {
      if (this.hasStampAttachments) return (this.getCurrentProjects.length === 0 || !this.isPictureTakenBefore)
      return this.getCurrentProjects.length === 0
    },
    isShowProjectField () {
      if (this.$store.state.common.isProjectExcluded) { /*  && this.isTimerRunnning */
        if (this.getCurrentProjects.length > 0) {
          const obj = this.getCurrentProjects && this.getCurrentProjects.length > 0 ? this.getCurrentProjects.find(x => x.number === 'TM001') : null
          if (obj) {
            if (!this.timerObj.project_id) this.saveProject(obj.id)
          }
        }
      }
      return !this.$store.state.common.isProjectExcluded
    },
    getBaseUrl () {
      let url = process.env.VUE_APP_URL
      url = url.split('/api')[0]
      return url
    },
    isWeekLocked () {
      return this.$store.state.common.isMonthLocked
    }
  },
  async beforeRouteEnter (to, from, next) {
    await next(vm => {
      if (from && from.path === '/login') vm.reloadApp = true
    })
  },
  async created () {
    await this.getCurrentDayHours()
    this.$store.dispatch('getGeneralSettings').then(() => {
      if (this.$store.state.common.isStempExcluded) this.$router.push('/')
    })
    const date = moment().format('DD.MM.YYYY')
    this.checkWeekLock(date)
    this.$store.dispatch('getWorktypes').then(() => {
      const result = this.getWorktypes.find(x => x.is_default && x.is_billable)
      if (result) this.timerObj.work_id = result.id
      // this.getProjects()
    })
    this.$eventBus.$on('languageChanged', (locale) => {
      locale = this.getMomentLocale(locale)
      this.currentDay = moment().locale(locale).format('dddd')
      this.currentDate = moment().locale(locale).format('DD.MM.YYYY')
    })
  },
  watch: {
    reloadApp (val) {
      if (val) this.$router.go()
    },
    'timerObj.project_id' (val) {
      if (val && val !== 0) {
        /*  save user_id * project_id in localstorage #7601-pin the project for preselect on next login */
        const pinProject = JSON.stringify({ user_id: this.getuserDetails.id, project_id: val })
        window.localStorage.setItem('stempPinnedProject', pinProject)
        /* #7601 ends */
        this.saveProject(val, false)
        this.getActivitiesForProject(val)
      } else this.listOfActivities = []
    },
    'currentDateTotal' (val) {
      if (this.startDatetotalHours) {
        var n = moment(this.timerObj.start_at, 'DD.MM.YYYY HH:mm:ss').toDate()
        n.setSeconds(+val * 60 * 60)
        this.time = n.toTimeString().slice(0, val)
      }
    },
    'timerObj.shift_id' (val) {
      localStorage.setItem('shiftValue', val)
      if (this.disableButton) this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.hourExceedNotification' })
    },
    'time' (val) {
      // var runningHours = this.time
      // runningHours = runningHours.split(':')
      // runningHours = Number(runningHours[0]) + (Number(runningHours[1]) + Number(runningHours[2] / 60)) / 60
      // this.currentDateTotalRunningHours = Number(this.currentDateTotal) + runningHours
      // const remainingTime = (24.00 - Number(this.currentDateTotal)).toFixed(2)
      // const runningTime = (this.currentDateTotalRunningHours - Number(this.currentDateTotal)).toFixed(2)
      // if (runningTime > remainingTime) {
      //   var decimalTimeString = remainingTime
      //   var n = new Date(0, 0)
      //   n.setSeconds(+decimalTimeString * 60 * 60)
      //   this.time = n.toTimeString().slice(0, 8)
      //   const timeHour = moment.duration(this.time).hours()
      //   const timeMinute = moment.duration(this.time).minutes()
      //   const timeSecond = moment.duration(this.time).seconds()
      //   setTimeout(() => {
      //     this.stempDate = moment(this.timerObj.start_at, 'DD-MM-YYYYTHH:mm:ss').add(timeHour, 'h').add(timeMinute, 'm').add(timeSecond, 's').format('YYYY-MM-DD HH:mm:ss')
      //   }, 100)
      //   if (this.webWorkerInstance) this.webWorkerInstance.terminate()
      //   this.webWorkerInstance = null
      //   this.updatePhasuedTime()
      //   // this.stempDate = moment(this.time, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
      //   // this.stopTimer()
      // }
      // if (this.currentDateTotalRunningHours > 24) {
      //   if (this.webWorkerInstance) this.webWorkerInstance.terminate()
      //   this.webWorkerInstance = null
      //   this.updatePhasuedTime()
      // }
      // if (!this.startDatetotalHours)
      this.shiftCalculations()
    }
  },
  async mounted () {
    this.getShifts()
    await this.getRunningStemp()
    var runningHours = this.time
    runningHours = runningHours.split(':')
    runningHours = Number(runningHours[0]) + (Number(runningHours[1]) + Number(runningHours[2] / 60)) / 60
    this.currentDateTotalRunningHours = Number(this.currentDateTotal) + runningHours
    this.$root.$on('stopShift', data => {
      if (data.stop) this.stopTimer()
    })
    // this.$root.$on('continueExceed', data => {
    //   if (data) this.startDatetotalHours = false
    // })
    if (this.test) {
      setTimeout(() => {
        this.stempDate = moment(this.startTime).add(this.shiftTime.hour, 'h').add(this.shiftTime.minute, 'm').add(this.shiftTime.second, 's').format('YYYY-MM-DD HH:mm:ss')
        this.stopTimer()
      }, 1000)
    }
    this.$root.$on('startTimeEmit', data => {
      this.startTime = data
    })
    // this.$root.$on('limitOfHours', data => {
    //   if (new Date().valueOf() > data) this.shiftEndDialog = true
    //   this.time = '08:00:00'
    //   // if (data) this.holdTime = true
    //   this.stempDate = moment(data).format('YYYY-MM-DD HH:mm:ss')
    // })
    this.timerObj.user_id = this.getuserDetails.id
  },

  methods: {
    checkWeekLock (date) {
      this.$store.dispatch('checkMonthLockedStatus', date).then((response) => { // true or false value
        if (response) this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.calendar.dayLocked' })// notification show for month locked
      })
    },
    getProjects () {
      const date = moment().format('MM.DD.YYYY')
      this.$api.execute('get', `projects/get_current_projects?date=${date}`).then((response) => {
        if (response && response.data.length > 0) {
          this.getCurrentProjects = this.$formatter.cloneVariable(response.data)
        }
      })
    },
    getActivitiesForProject (projectId) {
      this.$api.execute('get', `activities/get_by_project/${projectId}`)
        .then(response => {
          this.listOfActivities = this.$formatter.cloneVariable(response.data)
        })
    },
    start () {
      if (this.isWeekLocked) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.calendar.dayLocked' })
        return
      }
      if (this.getCurrentProjects.length === 0) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.projects.noActiveProjects' })
        return
      }
      if (this.hasStampAttachments) {
        this.fileUploadType = 1
        this.$refs.uploader.click()
      } else this.startTimer('')
      /* if (this.$refs.hourReference.$refs.validateForm.validate()) {
      } */
    },
    stopTimer () {
      this.continueTime = false
      this.shiftEndDialog = false
      if (this.hasStampAttachments) {
        if (!this.$refs.hourReference.$refs.validateForm.validate()) {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
          return
        }
        this.fileUploadType = 2
        this.$refs.uploader.click()
      } else this.stopTimerHandler()
    },
    stopTimerHandler () {
      if (this.$refs.hourReference.$refs.validateForm.validate() && this.timerObj.shift_id && this.$refs.shiftForm.validate()) {
        this.buttonDissable = true
        this.stempDate = this.stempDate ? this.stempDate : ((this.timerObj && this.timerObj.paused_at) ? moment(this.timerObj.paused_at, 'DD.MM.YYYYTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : null)
        const pausedTime = moment.duration(parseFloat(this.timerObj.break_hr), 'minutes').asHours()
        const ShiftTimer = this.time.split(':')
        const timeHours = Number(ShiftTimer[0]) + Number(ShiftTimer[1]) / 60
        if (Number(timeHours.toFixed(2)) >= Number(pausedTime)) {
          this.$api.execute('put', `stamptimes/stop/${this.timerObj.id}?datetime=${this.stempDate ? this.stempDate : moment().format('YYYY-MM-DD HH:mm:ss')}`, {})
            .then(response => {
              this.afterSaveRecord = response.data
              localStorage.setItem(`lastShiftUsed_${this.timerObj.user_id}`, this.timerObj.shift_id)
              localStorage.removeItem(`continueTimer_${this.getuserDetails.id}`)
              localStorage.removeItem('shiftValue')
              this.resetTimer()
              this.$root.$emit('snackbar', {
                snackbar: true,
                color: 'success',
                text: 'message.common.updatedSuccess'
              })
            })
            .finally(() => {
              this.buttonDissable = false
            })
        } else {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.shifts.notValidPausedTime' })
          this.buttonDissable = false
        }
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    },
    resetTimer () {
      if (this.webWorkerInstance) this.webWorkerInstance.terminate()
      this.webWorkerInstance = null
      this.$store.commit('clearTimerValue')
      this.$eventBus.$emit('stopTimer')
      this.time = '00:00:00'
      this.$refs.hourReference.$refs.validateForm.reset()
      this.timerObj = {}
      this.timerObj.shift_id = this.afterSaveRecord.shift_id
      window.localStorage.setItem('is_timer_running', false)
      this.allAttachments = []
      this.isPictureTakenBefore = false
    },
    saveRecord () {
      this.timerObj.user_id = this.getuserDetails.id
      const model = this.$formatter.cloneVariable(this.timerObj)
      // if (this.generalSettings.pause_setting_type !== 3) model.break_hr = 0
      model.start_at = model.start_at ? model.start_at : moment().format('YYYY-MM-DDTHH:mm:ss')
      model.break_hr = moment.duration(parseFloat(model.break_hr), 'minutes').asHours()
      const url = model.id ? `stamptimes/update/${model.id}` : 'stamptimes/start'
      const method = model.id ? 'put' : 'post'
      const msg = model.id ? 'message.common.updatedSuccess' : 'message.common.addedSucess'
      this.$api.execute(method, url, model).then(response => {
        if (method === 'post' && this.hasStampAttachments) this.saveFirstImageWithStampId(response.data.id)
        const model = this.$formatter.cloneVariable(response.data)
        if (model.break_hr) {
          var milliseconds = this.$formatter.getMilliseconds(model.break_hr)
          var duration = moment.duration(milliseconds)
          model.break_hr = parseInt(duration.asMinutes())
        } else model.break_hr = 0
        this.timerObj = this.$formatter.cloneVariable(model)
        this.$store.commit('setTimerObj', response.data)
        if (!this.timerObj.project_id) this.timerObj.project_id = 0
        this.$root.$emit('snackbar', {
          snackbar: true,
          color: 'success',
          text: msg
        })
      })
    },
    saveProject (id, isSetProjectId = true) {
      if (isSetProjectId && id !== undefined && id !== null) this.timerObj.project_id = id
      if (this.timerObj.id) this.saveRecord()
    },
    saveItem () {
      if (this.timerObj.id) this.saveRecord()
    },
    confirmCancel () {
      this.cancelLoading = true
      this.$api.execute('put', `stamptimes/cancel/${this.timerObj.id}`, null)
        .then(() => {
          var lastShift = Number(localStorage.getItem(`lastShiftUsed_${this.getuserDetails.id}`))
          if (lastShift) this.afterSaveRecord.shift_id = lastShift
          else this.afterSaveRecord.shift_id = this.shifts[0].id
          this.resetTimer()
          this.showCancelDialog = false
        }).finally(() => {
          this.cancelLoading = false
        })
    },
    setDefaultProject () {
      if (this.getCurrentProjects.length > 0) {
        const obj = this.getCurrentProjects && this.getCurrentProjects.length > 0 ? this.getCurrentProjects.find(x => x.number === 'TM001') : null
        if (obj) {
          if (!this.timerObj.project_id) this.saveProject(obj.id)
        }
      }
      return false
    },
    onFileChanged (e) {
      // check for the first / last image
      if (!e.target.files.length > 0) this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'select files' })
      else {
        this.uploadAttachmentsHandler(e.target.files)
      }
    },
    uploadAttachmentsHandler (files) {
      if (files.length < 1) return
      const formData = new FormData()
      for (var i = 0; i < files.length; i++) {
        const file = files[i]
        formData.append(file.name, file)
      }
      let url = ''
      if (this.fileUploadType === 1) url = 'stampattachments/upload_first_image'
      else if (this.fileUploadType === 2 || this.fileUploadType === 3) url = `stampattachments/upload/${this.timerObj.id}`
      this.$api.execute('post', url, formData)
        .then(response => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.timer.uploadSuccess' })
          if (this.fileUploadType === 1) {
            this.isPictureTakenBefore = true
            this.startTimer('')
          } else if (this.fileUploadType === 2 || this.fileUploadType === 3) {
            if (this.fileUploadType === 3) {
              this.getAllFiles()
              this.timerObj.files = []
            }
            if (this.fileUploadType === 2) {
              this.stopTimerHandler()
              this.allAttachments = []
            }
          }
        })
    },
    saveFirstImageWithStampId (id) {
      this.$api.execute('post', `stampattachments/add_first_image/${id}`, null)
        .then(() => {
          this.getAllFiles()
        })
    },
    getAllFiles () {
      this.$api.execute('get', `stampattachments/get_files_by_stamp/${this.timerObj.id}`)
        .then(response => {
          this.allAttachments = response.data
        })
    },
    storeFileChange () {
      this.fileUploadType = 3
      if (this.timerObj.files) this.uploadAttachmentsHandler(this.timerObj.files)
    },
    getMomentLocale (locale) {
      switch (locale) {
        case 'en': return 'en'
        case 'no': return 'nb'
        case 'po': return 'pl-PL'
        case 'li': return 'lt'
        case 'sv': return 'sv'
        default: return 'no'
      }
    },
    async getShifts () {
      await this.$api.execute('get', 'shiftsettings/get_active')
        .then((response) => {
          this.shifts = this.$formatter.cloneVariable(response.data)
          const lastShiftUsed = localStorage.getItem(`lastShiftUsed_${this.getuserDetails.id}`)
          const lastShift = this.shifts.find(x => x.id === Number(lastShiftUsed))
          const result = this.shifts.find(x => x.is_default)
          if (lastShiftUsed && lastShift) {
            this.timerObj.shift_id = Number(lastShiftUsed)
            this.timerObj.break_hr = lastShift.break_hr
          } else if (result) {
            this.timerObj.shift_id = result.id
            this.timerObj.break_hr = result.break_hr
          } else {
            this.timerObj.shift_id = this.shifts[0].id
            this.timerObj.break_hr = this.shifts[0].break_hr
          }
        })
    },
    shiftCalculations () {
      const shiftObj = this.shifts.find(x => x.id === Number(this.timerObj.shift_id))
      if (shiftObj && shiftObj.time_till) {
        const hours = moment(shiftObj.time_till, 'hh:mm').diff(moment(shiftObj.time_from, 'hh:mm'))
        const timeHour = moment.duration(this.time).hours()
        const timeMinute = moment.duration(this.time).minutes()
        const timeSecond = moment.duration(this.time).seconds()
        const shiftHour = moment.duration(hours).hours()
        const shiftMinute = moment.duration(hours).minutes()
        const shiftSecond = moment.duration(hours).seconds()
        // this.shiftTime = { hour: shiftHour, minute: shiftMinute, second: shiftSecond }
        if ((moment(`${shiftHour}:${shiftMinute}:${shiftSecond}`, 'HH:mm:ss').isSameOrBefore(moment(`${timeHour}:${timeMinute}:${timeSecond}`, 'HH:mm:ss')) || shiftHour < Number(this.time.toString().split(':')[0])) && this.continueTime) {
          const continueExceed = localStorage.getItem(`continueTimer_${this.getuserDetails.id}`)
          if (continueExceed) {
            this.shiftEndDialog = false
            this.continueExceeding()
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.shiftContinue' })
          } else {
            this.shiftEndDialog = true
          }
          this.continueTime = false
        }
        if (!this.yesContinueTime && moment(`${shiftHour}:${shiftMinute}:${shiftSecond}`, 'HH:mm:ss').isSameOrBefore(moment(`${timeHour}:${timeMinute}:${timeSecond}`, 'HH:mm:ss'))) {
          this.time = moment(`${shiftHour}:${shiftMinute}:${shiftSecond}`, 'HH:mm:ss').format('HH:mm:ss')
          this.timerObj.paused_at = moment(this.timerObj.start_at, 'DD-MM-YYYYTHH:mm:ss').add(shiftHour, 'h').add(shiftMinute, 'm').add(shiftSecond, 's').format('DD.MM.YYYYTHH.mm.ss')
        }
      }
    },
    continueExceeding () {
      localStorage.setItem(`continueTimer_${this.timerObj.user_id}`, this.timerObj.user_id)
      this.shiftEndDialog = false
      this.continueTime = false
      this.yesContinueTime = true
      this.timerObj.paused_at = null
    },
    async popupStopTimer () {
      await this.stopTimer()
      this.shiftEndDialog = false
    },
    getCurrentDayHours (date = moment().format('DD.MM.YYYY')) {
      if (date !== moment().format('DD.MM.YYYY')) this.startDatetotalHours = true
      const start = moment(date, 'DD.MM.YYYY').format('MM.DD.YYYY')
      const end = moment(date, 'DD.MM.YYYY').format('MM.DD.YYYY 23:59:59')
      const model = { start: start, end: end, users: [this.getuserDetails.id], showAllProjects: true }
      this.$api.execute('post', 'hours/filter', model).then((response) => {
        var hoursList = response.data
        let totalOfWork = 0
        let totalOfAbsense = 0
        for (var i = 0; i < hoursList.length; i++) {
          if (hoursList[i].type === 'working') {
            totalOfWork += hoursList[i].amount - hoursList[i].break_hr
          }
          if (hoursList[i].type === 'non_working') {
            totalOfAbsense += hoursList[i].amount
          }
          if (!isNaN(hoursList[i].break_hr)) {
            totalOfAbsense += hoursList[i].break_hr
          }
          this.currentDateTotal = (totalOfWork + totalOfAbsense).toFixed(2)
        }
        if (this.currentDateTotal >= 24) {
          this.disableButton = true
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.hourExceedNotification' })
        }
      })
    },
    updatePhasuedTime () {
      this.timerObj.user_id = this.getuserDetails.id
      const model = this.$formatter.cloneVariable(this.timerObj)
      if (this.generalSettings.pause_setting_type !== 3) model.break_hr = 0
      model.start_at = model.start_at ? model.start_at : moment().format('YYYY-MM-DDTHH:mm:ss')
      model.paused_at = model.paused_at ? model.paused_at : moment().format('YYYY-MM-DD HH:mm:ss')
      model.break_hr = moment.duration(parseFloat(model.break_hr), 'minutes').asHours()
      const url = `stamptimes/update/${model.id}`
      const method = model.id ? 'put' : 'post'
      const msg = model.id ? 'message.common.updatedSuccess' : 'message.common.addedSucess'
      this.$api.execute(method, url, model).then(response => {
        this.$store.commit('setTimerObj', response.data)
        this.exeedDate = moment(response.data.start_at, 'DD.MM.YYYY HH.mm.ss').format('DD.MM.YYYY')
        this.getCurrentDayHours(this.exeedDate)
        this.exeedValidaion = true
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: msg })
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.hourExceedNotification' })
      })
    },
    shiftChange () {
      if (this.shifts.length) {
        const shiftObj = this.shifts.find(x => x.id === Number(this.timerObj.shift_id))
        if (shiftObj && shiftObj.break_hr) this.timerObj.break_hr = shiftObj.break_hr ? shiftObj.break_hr : this.shifts[0].break_hr
        else this.timerObj.break_hr = this.shifts[0].break_hr
      }
      this.saveItem()
    }
  },
  beforeDestroy () {
    this.$root.$off('stopShift')
    // this.$root.$off('limitOfHours')
  }
}
</script>
<style lang="css" scoped>
.include_stamp_attachment {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;;
}
</style>
