<template>
  <div id="app">
    <v-app id="inspire" style="overflow: hidden;"><!-- v-scroll="onScroll" -->
      <v-snackbar top v-model="snackbar" :color="color">
        <template v-if="isHtml"><div v-html="text"></div> </template>
        <template v-else>
          {{ isException ? text : $t(text) }}
        </template>
      </v-snackbar>
      <v-main :style="$route.path === '/profile' ? 'padding: 43px 0px 0px' : ''"><!-- :class="$route.path === '/dashboard' ? 'dashboard_bg_color' : ''" -->
         <v-container fluid class="container--app py-0" :class="$route.path === '/dashboard' ? 'px-0' : 'px-0'">
          <!-- Header Component -->
          <header-logo v-if="isAuthenticated" />
          <router-view :class="timerData.isTimeRunning ? 'mb-8' : ''" :test="test" :shiftTime="shiftTime"></router-view>
         <!--  <v-fab-transition>
            <v-btn fab bottom right color="primary" id="scroll__top" fixed v-if="showScrollToTop" @click="scrollToTop">
              <v-icon dark>mdi-chevron-up</v-icon>
            </v-btn>
          </v-fab-transition> -->
         </v-container>
      </v-main>
      <layout v-if="isAuthenticated"></layout>
      <!--Common draft dialog-->
      <div v-if="showFormDialog" class="notes-div" style="top:30%!important">
        <!--  -->
      </div>
      <!-- New content avaliable via service worker -->
      <v-fade-transition>
        <v-alert prominent v-if="getNewContentAvailable" transition="scale-transition" type="info" class="pa-2" style="position: fixed;bottom:-10px;z-index: 100;">
          <v-row align="center">
            <v-col>{{ $t('message.common.newUpdate') }}</v-col>
            <v-col class="shrink">
              <v-btn outlined @click="loadUpdateContent">{{ $t('message.common.refresh') }}</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-fade-transition>
      <!-- Show install popup on iOS devices -->
      <v-dialog v-model="showInstalliOS" max-width="400px" persistent class="mt-0">
        <v-card>
          <v-card-title>
            <v-flex xs11>
              <h3>Install</h3>
            </v-flex>
            <v-flex xs1 class="text-xs-right">
              <v-icon @click="showInstalliOS = false">mdi-close</v-icon>
            </v-flex>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-5">
            <v-layout row wrap>
              <v-flex xs12>
                {{$t('message.common.install')}}
              </v-flex>
            </v-layout>
            <v-divider class="mt-3"></v-divider>
            <v-layout>
              <v-flex xs12 class="mt-2 text-xs-center">
                <b>{{$t('message.common.justTab')}} <v-icon color="#59a7ff">mdi-open-in-app</v-icon> {{$t('message.common.addToHome')}}</b>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Shift end popup -->
      <!-- <v-dialog persistent v-model="shiftEndDialog" width="500">
        <v-card>
          <v-card-title>{{ $t('message.common.confirmAddHour') }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4 font-weight-bold text-h6">
            {{ $t('message.common.doYouWantContinue') }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="success" @click="continueExceed()">{{ $t('message.common.yes') }}</v-btn>
            <v-btn class="error" @click="shiftStop()">{{ $t('message.common.no') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-app>
  </div>
</template>

<script>
import Layout from './components/Layout'
import { api, hostAppApi } from './plugins/axios_settings'
import { mapGetters } from 'vuex'
import goTo from 'vuetify/lib/services/goto'
import moment from 'moment'
import VueCookie from 'vue-cookie'
import VueSession from 'vue-session'
import Vue from 'vue'

Vue.use(VueSession)
export default {
  data () {
    return {
      showScrollToTop: false,
      text: '',
      snackbar: false,
      isException: false,
      color: '',
      links: [],
      formObj: {},
      formReferences: {},
      showFormDialog: false,
      discardDraftDialog: false,
      selectedDraftIndex: '',
      fromApp: true,
      showInstalliOS: false,
      notificationTitle: '',
      notificationImage: '',
      isHtml: true,
      // shiftEndDialog: false,
      shifts: [],
      time: '00:00:00',
      test: false,
      shiftTime: null
    }
  },
  session: {
    keepAlive: true, // Extend session on user activity
    persist: true// Store session data in cookies or local storage
  },
  components: {
    Layout,
    'header-logo': () => import('./components/HeaderLogo')
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getNewContentAvailable', 'timerData', 'getuserDetails', 'getHostRefApi', 'userId', 'getTimerObj'])
  },
  created () {
    if (!this.$cookie.get('WelditPwa_host-ref')) {
      this.$store.dispatch('logout')
      this.$router.push('/login').catch(() => {})
    }
    this.loadFeaturesAndSetPermissions()
  },
  mounted () {
    this.$session.set('lastActivity', Date.now())
    document.addEventListener('mousemove', this.handleUserActivity)
    document.addEventListener('keydown', this.handleUserActivity)
    this.$root.$on('timer', data => {
      this.time = data
      this.shiftCalculations()
    })
    if (VueCookie.get(process.env.VUE_APP_TOKEN)) {
      this.getShifts()
    }
    /* Set notification title based on the host */
    // const host = this.$store.state.common.host
    switch (process.env.VUE_APP_HOST_INCLUDES) {
      case 'axentiv':
        this.notificationTitle = 'AXENTIV'
        this.notificationImage = 'axentiv'
        break
      case 'weldit':
        this.notificationTitle = 'WELD IT'
        this.notificationImage = 'weldit'
        break
      case 'novap':
        this.notificationTitle = 'NOVAP'
        this.notificationImage = 'novap'
        break
      default:
        break
    }
    /* if (host.includes(process.env.VUE_APP_HOST_INCLUDES)) {
      this.notificationTitle = 'AXENTIV'
      this.notificationImage = 'axentiv'
    } else {
      this.notificationTitle = 'WELD IT'
      this.notificationImage = 'weldit'
    } */
    if (this.$cookie.get(process.env.VUE_APP_TOKEN)) {
      this.$store.dispatch('getGeneralSettings')
      // get tasks count
      this.getTasksCount()
    }
    this.$root.$on('snackbar', data => {
      this.snackbar = data.snackbar
      this.color = data.color
      this.text = data.text
      this.isException = data.isException
      this.isHtml = data.isHtml || false
    })
    const showniOSBanner = window.localStorage.getItem('TimerPwa_ShownInstallBanner') || false
    if (this.isIos() && !this.isInStandaloneModeIos() && !showniOSBanner) {
      this.showInstalliOS = true
      window.localStorage.setItem('TimerPwa_ShownInstallBanner', true)
    } else {
      this.showInstalliOS = false
    }
    this.$eventBus.$on('checkHoursExceeding', () => {
      this.checkHoursExceeding()
    })

    /* get running time if path not /stemp */
    if (this.$route.path !== '/stemp' && this.$cookie.get(process.env.VUE_APP_TOKEN)) {
      this.$store.dispatch('getRunningTimer', this.getuserDetails.id)
        .then((data) => {
          if (data) {
            this.checkHoursExceeding()
          }
        })
    }
    /* Task Count Update on changing the task page changes */
    this.$eventBus.$on('updateTaskCount', () => this.getTasksCount())
  },
  methods: {
    getShifts () {
      this.$api.execute('get', 'shiftsettings/get_active')
        .then((response) => {
          this.shifts = this.$formatter.cloneVariable(response.data)
          this.shiftCalculations()
        })
    },
    shiftCalculations () {
      const shiftObj = this.shifts.find(x => x.id === Number(this.getTimerObj ? this.getTimerObj.shift_id : null))
      if (shiftObj && shiftObj.time_till) {
        const hours = moment(shiftObj.time_till, 'hh:mm').diff(moment(shiftObj.time_from, 'hh:mm'))
        const timeHour = moment.duration(this.time).hours()
        const timeMinute = moment.duration(this.time).minutes()
        const timeSecond = moment.duration(this.time).seconds()
        const shiftHour = moment.duration(hours).hours()
        const shiftMinute = moment.duration(hours).minutes()
        const shiftSecond = moment.duration(hours).seconds()
        this.shiftTime = { hour: shiftHour, minute: shiftMinute, second: shiftSecond }
        if (moment(`${shiftHour}:${shiftMinute}:${shiftSecond}`, 'HH:mm:ss').isSameOrBefore(moment(`${timeHour}:${timeMinute}:${timeSecond}`, 'HH:mm:ss'))) this.shiftEndDialog = true
      }
    },
    loadFeaturesAndSetPermissions () {
      this.$i18n.locale = window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) ? window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) : 'no'
      const authToken = this.$cookie.get(process.env.VUE_APP_TOKEN)
      const user = this.$cookie.get(process.env.VUE_APP_USER) ? JSON.parse(this.$cookie.get(process.env.VUE_APP_USER)) : {}
      const authDetails = { authToken, user }
      if (authToken) {
        hostAppApi.get(`${this.getHostRefApi}check_and_get_tenant_features?user_id=${this.userId}&is_pwa=1`, { headers: { language: this.$i18n.locale } })
          .then((response) => {
            if (response && response.data) {
              if (response.data.features) {
                window.localStorage.removeItem(process.env.VUE_APP_FEATURES_LIST)
                window.localStorage.setItem('UserRole', JSON.stringify(response.data.role))
                const features = response.data.features
                if (features.timer.enabled) {
                  setTimeout(() => {
                    window.localStorage.setItem(process.env.VUE_APP_FEATURES_LIST, this.$formatter.encryptString(JSON.stringify(features)))
                  }, 0)
                } else {
                  this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.timerFeatureDisabled' })
                  this.$store.dispatch('logout')
                  this.$router.push('/login').catch(() => {})
                }
              }
              if (response.data.permissions && response.data.role) {
                /* set user permission and roles  */
                var date = new Date()
                date.setDate(date.getDate() + 4)
                const obj = JSON.stringify({ permissions: response.data.permissions || {}, role: response.data.role || {} })
                if (obj) {
                  this.$cookie.set(process.env.VUE_APP_PERMISSIONS_AND_ROLES, this.$formatter.encryptString(obj), { expires: date })
                  this.$store.commit('setPermissions', JSON.parse(obj))
                }
              }
            }
          })
        this.$store.commit('setAuth', authDetails)
      }
      /* set permission and roles in store-state variables */
      let permissionsAndRoles = this.$cookie.get(process.env.VUE_APP_PERMISSIONS_AND_ROLES)
      if (permissionsAndRoles) {
        permissionsAndRoles = JSON.parse(this.$formatter.decryptString(permissionsAndRoles))
        if (permissionsAndRoles) this.$store.commit('setPermissions', permissionsAndRoles)
      }
    },
    isIos () {
      const userAgent = window.navigator.userAgent.toLowerCase() || navigator.userAgent.toLowerCase() || navigator.vendor.toLowerCase() || navigator.platform.toLowerCase()
      return (/iphone|ipad|ipod/.test(userAgent)) || (/macintosh/.test(userAgent) && 'ontouchend' in document)
    },
    isInStandaloneModeIos () {
      return ('standalone' in window.navigator) && (window.navigator.standalone)
    },
    onScroll () {
      if (window.pageYOffset <= 0) this.showScrollToTop = false
      else this.showScrollToTop = true
    },
    scrollToTop () {
      goTo(0)
    },
    loadUpdateContent () {
      this.$store.dispatch('reloadNewContent')
    },
    checkHoursExceeding () {
      // var self = this
      // Notification.requestPermission().then(function (result) {
      //   if (result === 'granted') {
      //     if (window.localStorage.getItem('startedTimeOfTimer')) {
      //       const currentDate = moment(moment().format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss')
      //       const startDate = moment(window.localStorage.getItem('startedTimeOfTimer'), 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss')
      //       const secondsCount = Math.abs(currentDate.diff(startDate, 'seconds'))
      //       const seconds = isNaN(secondsCount) ? 0 : secondsCount
      //       // const hours = Math.floor(seconds / 3600)
      //       // const minutes = Math.floor(seconds / 60)
      //       // if (hours >= 8 && 'serviceWorker' in navigator) {
      //       //   navigator.serviceWorker.getRegistration().then(function (reg) {
      //       //     var options = {
      //       //       body: self.$t('message.timer.hoursExceeding'),
      //       //       icon: require(`../public/img/${self.notificationImage}.png`)
      //       //     }
      //       //     if (reg) {
      //       //       reg.showNotification(self.notificationTitle, options)
      //       //     }
      //       //   })
      //       //   self.$store.state.common.notifiedCount = 1
      //       // }
      //     }
      //   }
      // })
    },
    getTasksCount () {
      hostAppApi.get(`${this.getHostRefApi}get_task_count?user_id=${this.userId}`)
        .then((response) => {
          if (response.data) {
            const taskCount = response.data.task_count || 0
            window.localStorage.setItem(process.env.VUE_APP_TASKS_COUNT, taskCount)
            try {
              this.$refs.headerLogo.$data.tasksCount = `${taskCount}`
            } catch (e) {}
          }
        })
    },
    handleUserActivity () {
      this.$session.set('lastActivity', Date.now())
    }
  },
  beforeMount () {
    const text = 'Authentication Failed'
    const apis = [api, hostAppApi]
    hostAppApi.interceptors.request.use((config) => {
      config.params = {
        ...config.params,
        is_pwa: 1,
        user_id: this.userId
      }
      return config
    })
    apis.forEach(element => {
      element.interceptors.request.use((config) => {
        config.headers.language = this.$i18n.locale || 'en'
        return config
      })
      element.interceptors.response.use(
        (response) => {
          if (response && response.data && response.data.status && response.data.status !== 'success' && response.data.errorcode && response.data.errorcode !== 200) {
            if (response.data.errorcode === 499) {
              const text = this.$t('message.common.sharePoint')
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: text, isException: true })
              return Promise.reject(response)
            } else {
              const text = response.data.message
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(text), isException: true })
              return Promise.reject(response)
            }
          } else {
            return Promise.resolve(response)
          }
        },
        (error) => {
          if (error.response) {
            let exception = error.response
            if (error.response.data) exception = `Status Code : ${error.response.status}, Message : ${error.response.data}`
            else exception = `Status Code : ${error.response.status}, Message : ${error.response.statusText}`
            var url = error.response.config && error.response.config.url ? error.response.config.url : undefined
            if (url && url.includes(this.getHostRefApi) && [422, 404].includes(error.response.status)) {
              if (error.response.status === 404) {
                var htmlString = new DOMParser().parseFromString(error.response.data, 'text/html').documentElement.textContent
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: htmlString, isException: true })
              } else if (error.response.status === 422) {
                const errorObj = error.response.data
                const listErrors = Object.keys(errorObj).filter(x => errorObj[x] && errorObj[x].length).map(x => `${this.$formatter.stringToProperCase(x)}: ${errorObj[x][0]}`)
                let errorHtml = ''
                listErrors.forEach(x => {
                  errorHtml += `<p class="ma-0">${x}</p>`
                })
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: errorHtml, isException: true, isHtml: true })
              }
            } else if (error.response.status === 401) {
              this.$root.$emit('snackbar', { snackbar: false, color: 'error', text, isException: true }) // Set false to stop "Authentication Faild" snackbar message in after refresh, it's not stop login failed message, both are different
              this.$store.dispatch('logout')
              this.$router.push('/login').catch(() => {})
            } else if (error.response.status === 499) {
              const text = this.$t('message.common.sharePoint')
              this.$root.$emit('snackbar', { snackbar: false, color: 'error', text, isException: true }) // Set false to stop "Share point credential issue" snackbar message in after refresh, it's not stop login failed message, both are different
            } else if (error.response.status === 500) {
              const text = 'Somthing went wrong'
              this.$root.$emit('snackbar', { snackbar: false, color: 'error', text, isException: true }) // Set false to stop "Share point credential issue" snackbar message in after refresh, it's not stop login failed message, both are different
            } else {
              const text = error.response ? exception : error.message
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(text), isException: true })
            }
          } else {
            const text = 'Somthing went wrong'
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(text), isException: true })
          }
          // else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(exception), isException: true })
          // else {
          //   console.log(error.response)
          //   if (error.response.status === 499) {
          //     const text = this.$t('message.common.sharePoint')
          //     this.$root.$emit('snackbar', { snackbar: false, color: 'error', text, isException: true }) // Set false to stop "Share point credential issue" snackbar message in after refresh, it's not stop login failed message, both are different
          //   } else {
          //     const text = 'Somthink went wrong'
          //     this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(text), isException: true })
          //   }
          // }
          return Promise.reject(error)
        }
      )
    })
  },
  watch: {
    '$i18n.locale' (val) {
      this.$eventBus.$emit('languageChanged', val)
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('checkHoursExceeding')
    this.$eventBus.$off('updateTaskCount')
    this.$root.$off('popupDialog')
  },
  destroyed () {
    this.$root.$off('snackbar')
    this.$root.$off('timer')
  }
}
</script>
<style lang="scss">
  @import './assets/css/app.scss';
  .dashboard_bg_color {
    background: linear-gradient(180deg, #578ebe 50%, #ffffff 50%);
  }
  /* styles for autocomplete */
  .v-autocomplete__content > .v-select-list > .v-list-item {
    min-height: 32px;
    margin-bottom: 4px;
  }
  .v-autocomplete__content > .v-select-list > .v-list-item > .v-list-item__content {
    padding: 0px 3px 2px !important;
    border-bottom: rgba(0,0,0,.12);
  }
  .v-autocomplete__content > .v-select-list > .v-list-item > .v-list-item__content > .v-list-item__title {
    white-space: normal;
  }
  /* added below code when css size issue occured after doing npm install */
  .row {
    margin: 0px;
  }
</style>
