import moment from 'moment'
export default {
  methods: {
    getRunningStemp () {
      this.$store.dispatch('getRunningTimer', this.getuserDetails.id)
        .then((data) => {
          if (data) {
            const model = this.$formatter.cloneVariable(data)
            if (model.break_hr) {
              var milliseconds = this.$formatter.getMilliseconds(model.break_hr)
              var duration = moment.duration(milliseconds)
              model.break_hr = parseInt(duration.asMinutes())
            } else model.break_hr = 0
            this.timerObj = this.$formatter.cloneVariable(model)
            /* for include_stamp_attachment */
            if (this.hasStampAttachments && this.timerObj.id) {
              this.isPictureTakenBefore = true
              this.getAllFiles()
            }
            /* end */
            if (!this.timerObj.project_id) this.timerObj.project_id = 0
            if (data.time && !data.paused_at) {
              this.startTimer(data.time)
              this.isTimerRunning = true
              this.$store.state.common.isTimerRunning = true
            } else {
              const start = moment(data.start_at, 'DD.MM.YYYYTHH:mm:ss')
              const pause = moment(data.paused_at, 'DD.MM.YYYYTHH:mm:ss')
              var seconds = pause.diff(start, 'seconds') % 60
              if (seconds < 9) seconds = '0' + seconds
              var minutes = pause.diff(start, 'minutes') % 60
              if (minutes < 9) minutes = '0' + minutes
              var hours = pause.diff(start, 'hours')
              if (hours < 9) hours = '0' + hours
              var hourStrings = hours + ':' + minutes + ':' + seconds
              this.time = hourStrings
              this.isTimerRunning = true
              this.$store.state.common.isTimerRunning = true
              this.$store.commit('setTimerValue', this.time)
              window.localStorage.setItem('is_timer_running', true)
            }
          } else if (this.$route.path === '/stemp') {
            let pinnedProject = window.localStorage.getItem('stempPinnedProject')
            pinnedProject = pinnedProject ? JSON.parse(pinnedProject) : null
            setTimeout(() => {
              if (pinnedProject && pinnedProject.project_id && (pinnedProject.user_id === this.getuserDetails.id)) this.timerObj.project_id = pinnedProject.project_id || 0
            }, 50)
          }
        })
    },
    startTimer (time) {
      if (!time) time = new Date()
      // if (new Date().valueOf() <= new Date(time).valueOf() + 1000 * 60 * 60 * 8) {
      if (typeof (Worker) !== 'undefined') {
        if (this.webWorkerInstance === null) {
          this.webWorkerInstance = new Worker(`../../js/timer.js?date=${time}`)
        }
        this.webWorkerInstance.onmessage = (event) => {
          this.$root.$emit('startTimeEmit', time)
          this.time = event.data
          // if (this.stopEmit) this.$root.$emit('timer', this.time)
        }
        if (this.$route.path === '/stemp') {
          this.$store.commit('setTimerValue', this.time)
          window.localStorage.setItem('is_timer_running', true)
          if (!this.timerObj.id) this.saveRecord()
        }
      } else {
        alert('Browser is not supporting web workers!')
      }
      var feature = JSON.parse(this.$formatter.decryptString(window.localStorage.getItem(process.env.VUE_APP_FEATURES_LIST)))
      if (feature) {
        this.$root.$emit('stempTimerActive', feature)
      }
      // } else {
      //   this.$root.$emit('snackbar', { snackbar: true, color: 'warning', text: 'message.timer.hoursExceeding' })
      //   const date = new Date(new Date(time).valueOf() + 1000 * 60 * 60 * 8)
      //   this.$root.$emit('limitOfHours', date)
      // }
    }
  }
}
