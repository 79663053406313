const orders = [/* {
  path: '/customer_orders',
  component: () => import('@/views/Warehouse/orders/customer/View'),
  meta: { requiresAuth: true },
  children: [{
    path: '/',
    component: () => import('@/views/Warehouse/orders/customer/List')
  }, {
    path: ':order_id',
    component: () => import('@/views/Warehouse/orders/customer/Item')
  }, {
    path: ':order_id/pick',
    name: 'customer_pick_order',
    component: () => import('@/views/Warehouse/orders/customer/Pick')
  }]
},  */{
    path: '/supplier_orders',
    component: () => import('@/views/Warehouse/orders/supplier/View'),
    meta: { requiresAuth: true },
    children: [{
      path: '/',
      component: () => import('@/views/Warehouse/orders/supplier/NewList')
      // component: () => import('@/views/Warehouse/orders/supplier/List')
    }, {
      path: ':order_id',
      component: () => import('@/views/Warehouse/orders/supplier/Item')
    }, {
      path: ':order_id/pick',
      name: 'supplier_pick_order',
      component: () => import('@/views/Warehouse/orders/supplier/Pick')
    }]
  }, {
    path: '/adjustment_orders',
    component: () => import('@/views/Warehouse/orders/adjustment/View'),
    meta: { requiresAuth: true },
    children: [{
      path: '/',
      component: () => import('@/views/Warehouse/orders/adjustment/NewList')
      // component: () => import('@/views/Warehouse/orders/adjustment/List')
    }, {
      path: ':order_id',
      component: () => import('@/views/Warehouse/orders/adjustment/Item')
    }, {
      path: ':order_id/adjust',
      name: 'adjust_order',
      component: () => import('@/views/Warehouse/orders/adjustment/Adjust')
    }]
  }, {
    path: '/transfer_orders',
    component: () => import('@/views/Warehouse/orders/transfer/View'),
    meta: { requiresAuth: true },
    children: [{
      path: '/',
      component: () => import('@/views/Warehouse/orders/transfer/NewList')
      // component: () => import('@/views/Warehouse/orders/transfer/List')
    }, {
      path: ':order_id',
      component: () => import('@/views/Warehouse/orders/transfer/Item')
    }, {
      path: ':order_id/transfer/:mode',
      name: 'transfer_order',
      component: () => import('@/views/Warehouse/orders/transfer/Mode')
    }]
  }, {
    path: '/product_view',
    component: () => import('@/views/Warehouse/orders/ProductView'),
    meta: { requiresAuth: true }
  }]
export default orders
