<template>
  <div>
    <!-- DELETE WARNING -->
    <v-dialog v-model="showDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('message.common.deleteConfirm') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          {{ $t('message.common.areYouSure') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" id="deleteConfirm" :loading="loading" @click="confirmDelete">{{ $t('message.common.yes')
            }}</v-btn>
          <v-btn color="primary" id="deleteClose" @click="showDialog = false">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETE WARNING  -->
    <v-bottom-navigation app dark class="grey darken-3 small_device_custom_spacing" max-width="1264px"
      :style="`left: ${bottomNavigationLeftValue}px !important;`">
      <div class="d-flex">
        <template v-for="(button, index) in getMenuLinks">
          <v-btn class="fixbuttondesign" v-if="button.isShow !== undefined ? button.isShow : true"
            :key="`${index}_menu_navigation`" icon small style="color: #fff !important;" :to="button.to">
            <span>{{ $t(button.text) }}</span>
            <v-icon>{{ button.icon }}</v-icon>
          </v-btn>
        </template>
        <v-menu offset-y top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="btnmsgbox" v-bind="attrs" icon v-on="on" small>
              <span class="moretextmsg">{{ $t('message.layout.more') }}</span>
              <v-icon dark>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <span class="overflow-text pl-3 font-weight-medium">{{ userDetails.name }}</span>
            <v-divider></v-divider>
            <v-list-item dense v-for="(item, index) in menuItems" :key="index" @click="menuActions(item.click, item.value)">
              <v-list-item-title :style="{ 'line-height': '3'}" v-if="item.value !== 'autoLogout'">
                {{ $t(item.title) }}
              </v-list-item-title>
              <template v-else>
                <v-list-item-title :style="{ 'line-height': '3'}" @click.stop>{{ $t(item.title) }}</v-list-item-title>
                <v-switch class="v-icon mb-n4 mt-0 ml-2" color="#000" v-model="switchValue" @click.stop @change="handleSwitchChange($event)"></v-switch>
              </template>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-bottom-navigation>

    <!-- DIALOG TO CONFIRM // CANCEL -->
    <v-dialog v-model="showConfirmationDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t(confirmationPayload.title) }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          {{ $t(confirmationPayload.text) }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" id="confirmToProceed" :loading="loading" @click="confirmProceed">{{
            $t('message.common.yes') }}</v-btn>
          <v-btn color="error" id="cancelProceed" @click="showConfirmationDialog = false">{{ $t('message.common.no')
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG TO CONFIRM // CANCEL -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CONSTANTS from '@/assets/json/constants.json'
import moment from 'moment'
import FeaturesMixin from '@/mixins/features_list'
import { hostAppApi } from '../plugins/axios_settings'
export default {
  mixins: [FeaturesMixin],
  data () {
    return {
      loading: false,
      menuLinks: [
        { name: 'calendar', icon: 'mdi-plus', to: '/calendar/actions', text: 'message.timer.hours' },
        { name: 'calendar', icon: 'mdi-calendar', to: '/calendar', text: 'message.layout.calendar' },
        { name: 'dashboard', icon: 'mdi-home', to: '/dashboard', text: 'message.layout.dashboard' },
        { name: 'files', icon: 'mdi-file', to: '/files', text: 'message.layout.files' }
      ],
      switchValue: false,
      intervalId: null,
      deliveryLoading: false,
      bottomNavigationLeftValue: 0
    }
  },
  computed: {
    ...mapGetters(['showDialog', 'userDetails', 'deletePayload', 'getuserDetails', 'showConfirmationDialog', 'confirmationPayload', 'getHostRefApi']),
    getMenuLinks () {
      const listitems = []
      this.menuLinks.forEach(item => {
        if (this.checkFeatureEnabled(item.name)) listitems.push(item)
      })
      return listitems
      /* const layoutMenuItems = this.$formatter.cloneVariable(this.menuLinks)
      if (this.$store.state.common.isCalendarFormExcluded) layoutMenuItems.splice(0, 1)
      return layoutMenuItems */
    },
    showDialog: {
      get () {
        return this.$store.state.common.showDialog
      },
      set (val) {
        if (!val) this.$store.commit('hideDialog')
      }
    },
    showConfirmationDialog: {
      get () {
        return this.$store.state.common.showConfirmationDialog
      },
      set (val) {
        if (!val) this.$store.commit('hideConfirmationDialog')
      }
    },
    menuItems () {
      const languages = [
        { title: 'message.common.english', value: 'en', click: 'language' },
        { title: 'message.common.norwegian', value: 'no', click: 'language' },
        { title: 'message.common.lithuanian', value: 'lt', click: 'language' },
        { title: 'message.common.polish', value: 'pl', click: 'language' },
        { title: 'message.common.swedish', value: 'sv', click: 'language' }
      ]
      const languageIndex = languages.findIndex(x => x.value === this.$i18n.locale)
      languages.splice(languageIndex, 1)
      languages.push({ title: 'message.layout.autoLogout', value: 'autoLogout' }, { title: 'message.layout.logout', click: 'logout' })
      return languages
      /* let language
      if (this.$i18n.locale === 'en') {
        language = { title: 'message.common.norwegian', value: 'no', click: 'language' }
      } else if (this.$i18n.locale === 'no') language = { title: 'message.common.english', value: 'en', click: 'language' }
      const items = [language, { title: 'message.layout.logout', click: 'logout' }]
      return items */
    }
  },
  created () {
    /* since this is called every reload, adding the commit of isprojectexcluded and isdeviationexcluded here itself */
    this.$store.commit('isProjectExcluded', this.getFeatureObj('projects') ? !this.getFeatureObj('projects').enabled : false)
    window.addEventListener('resize', () => {
      if (window.innerWidth > 1262) {
        this.bottomNavigationLeftValue = (window.innerWidth - 1262) / 2
      } else this.bottomNavigationLeftValue = 0
    })
    if (this.$vuetify.breakpoint.mdAndUp) this.bottomNavigationLeftValue = (window.innerWidth - 1262) / 2
  },
  methods: {
    menuActions (action, value) {
      switch (action) {
        case 'logout':
          this.$store.dispatch('logout')
          this.$router.push('/login')
          break
        case 'language':
          this.changeLang(value)
          break
        default:
          break
      }
    },
    changeLang (language) {
      this.$i18n.locale = language
      window.localStorage.setItem(process.env.VUE_APP_LOCALE_STORAGE, language)
    },
    confirmDelete () {
      this.loading = true
      const model = this.deletePayload
      if (model.isDocumentDelete) {
        this.$eventBus.$emit('deleteSuccess', model)
        this.loading = false
      } else if (model.isSingle) {
        this.$store.dispatch('deleteSingleRecordHandler', model)
          .then(() => {
            this.$eventBus.$emit('deleteSuccess', model.module)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$store.dispatch('deleteHandler', model)
          .then(() => {
            this.$eventBus.$emit('deleteSuccess', model.module)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    confirmProceed () {
      this.loading = true
      const model = this.confirmationPayload
      this.$eventBus.$emit('confirmedToProceed', model.module, model.saveData)
      setTimeout(() => {
        this.loading = false
      }, 50)
    },
    deliverHours () {
      if (this.$route.name === 'calendar') this.$eventBus.$emit('deliverHoursHandler')
      else {
        this.deliveryLoading = true
        const startOfWeek = moment().isoWeekYear(moment().isoWeekYear()).isoWeek(moment().isoWeek()).startOf('isoweek')
        const start = startOfWeek.format('MM.DD.YYYY')
        const end = moment(startOfWeek).add('7', 'days').add('-1', 'seconds').format('MM.DD.YYYY HH:mm:ss')
        const model = { start: start, end: end, showAllProjects: true, users: [this.getuserDetails.id] }
        this.$api.execute('post', 'hours/filter', model)
          .then((response) => {
            let appHourEntries = response.data
            appHourEntries = appHourEntries.filter(x => x.state === CONSTANTS.NOT_DELIVERED)
            const hourIds = appHourEntries.map(x => x.id)
            const model = { ids: hourIds, parameters: { state: CONSTANTS.DELIVERED } }
            if (this.$formatter.isArrayHasData(hourIds)) {
              this.$api.execute('put', 'hours/bulk_update', model)
                .then((response) => {
                  this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.timer.deliveredMsg' })
                }).finally(() => {
                  this.deliveryLoading = false
                })
            } else {
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.timer.noHours' })
              this.deliveryLoading = false
            }
          })
      }
    },
    handleSwitchChange (e) {
      const UserRole = localStorage.getItem('UserRole')
      const parsedData = JSON.parse(UserRole)
      const body = {
        auto_logout_enabled: e,
        user_id: parsedData?.pivot?.user_id
      }
      hostAppApi.post(`${this.getHostRefApi}automatic-logout`, body)
        .then((response) => {
          this.switchValue = response?.data?.auto_logout_enabled
          if (response?.data?.auto_logout_enabled) {
            this.startInactivityTimer() // start inactivity timer
          } else {
            this.stopInactivityTimer() // stop inactivity timer
          }
        })
        .catch((error) => {
          console.log('error', error?.data?.message)
        })
    },
    automaticLogoutGet () {
      hostAppApi.get(`${this.getHostRefApi}automatic-logout`)
        .then((response) => {
          this.switchValue = response?.data?.auto_logout_enabled
          if (response?.data?.auto_logout_enabled) {
            this.startInactivityTimer() // start inactivity timer
          } else {
            this.stopInactivityTimer() // stop inactivity timer
          }
        })
    },
    startInactivityTimer () {
      this.intervalId = setInterval(() => {
        const lastActivity = this.$session.get('lastActivity')
        const currentTime = Date.now()
        const inactivityDuration = currentTime - lastActivity
        if (inactivityDuration > 120000) { // inactivity duration is 2 minutes
          this.$store.dispatch('logout')
          this.$router.push('/login')
        }
      }, 1000) // Check every second/
    },
    stopInactivityTimer () {
      if (this.intervalId) {
        clearInterval(this.intervalId) // Clear the interval
      }
    }
  },
  beforeDestroy () {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  mounted () {
    this.automaticLogoutGet()
  }
}
</script>
<style scoped>
@media screen and (max-width: 399px) {

  .small_device_custom_spacing>a.v-btn,
  .small_device_custom_spacing>button.v-btn {
    min-width: 75px;
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 379px) {

  .small_device_custom_spacing>a.v-btn,
  .small_device_custom_spacing>button.v-btn {
    min-width: 70px;
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 359px) {

  .small_device_custom_spacing>a.v-btn,
  .small_device_custom_spacing>button.v-btn {
    min-width: 65px;
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 329px) {

  .small_device_custom_spacing>a.v-btn,
  .small_device_custom_spacing>button.v-btn {
    min-width: 60px;
    font-size: 0.6rem;
  }
}

.fixbuttondesign {
  min-width: 110px !important;
}

@media only screen and (max-width: 575px) {
  .fixbuttondesign {
    min-width: 75px !important;
  }
}

@media only screen and (max-width: 575px) {

  .fixbuttondesign span,
  .moretextmsg {
    display: none;
  }

  .fixbuttondesign,
  .btnmsgbox {
    min-width: 60px !important;
    padding: 0 !important;
  }
}
</style>
