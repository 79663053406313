export default {
  data () {
    return {
      isShowDocumentErrors: false
    }
  },
  computed: {
    $_requiredValidation () {
      return [val => !!val || this.$t('message.common.required')]
    },
    $_zeroValidation () {
      return [val => (val !== null && val !== undefined) || this.$t('message.common.required')]
    },
    $_qtyValidation () {
      return [
        val => !!val || this.$t('message.common.required'),
        val => (val && val > 0) || this.$t('message.common.required')
      ]
    },
    $_multiSelectValidation () {
      return [
        val => !!val || 'Required',
        val => (val && val.length > 0) || 'Required'
      ]
    },
    $_HourValidation () {
      return [
        val => !!val || 'Required',
        val => this.$formatter.replaceCommaWithDot(val) <= 24 || this.$t('message.common.invaliHours'),
        val => this.$formatter.replaceCommaWithDot(val) > 0 || this.$t('message.common.invaliHours')
      ]
    },
    $_emailAndRequiredValidation () {
      return [
        (v) => !!(v) || 'Required',
        (v) => v ? /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || this.$t('message.common.invalidEmail') : true
      ]
    },
    $_numberAndRequiredValidation () {
      return [
        (v) => !!(v) || 'Required',
        val => (val) ? /^[0-9.,]+$/.test(val) || this.$t('message.common.notValidNumber') : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || this.$t('message.common.notValidNumber') : true
      ]
    },
    $_numberValidation () {
      return [
        val => (val) ? /^[0-9]+$/.test(val) || this.$t('message.common.notValidNumber') : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || this.$t('message.common.notValidNumber') : true
      ]
    },
    $_norwegianNumberValidation () {
      return [
        val => (val) ? /^[0-9.,]+$/.test(val) || this.$t('message.common.notValidNumber') : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || this.$t('message.common.notValidNumber') : true
      ]
    },
    $_norwegianNumberMinusValidation () {
      return [
        val => (val) ? /^-?[0-9.,]+$/.test(val) || this.$t('message.common.notValidNumber') : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || this.$t('message.common.notValidNumber') : true
      ]
    },
    $_emailValidation () {
      return [
        (v) => v ? /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || this.$t('message.common.invalidEmail') : true
      ]
    },
    $_discountValidation () {
      return [
        (val) => val ? (this.$formatter.replaceCommaWithDot(val) <= 100 && this.$formatter.replaceCommaWithDot(val) >= 0) || this.$t('message.common.notValidNumber') : true
      ]
    },
    $_numberLength () {
      return [
        (val) => val ? (val && val.length <= 4) || this.$t('message.common.maxCharacters', { charCount: 4 }) : true
      ]
    },
    $_fieldNameValidation () {
      return [
        (v) => v ? /^[a-zA-Z0-9_]*$/.test(v) || this.$t('message.common.invalidName') : true
      ]
    },
    $_columnValidation () {
      return [
        v => !!v || 'Required',
        v => (v <= 12 && v >= 1) || 'Enter columns b/w 1 to 12'
      ]
    },
    $_accountType () {
      return [
        { text: 'Account', value: 'account' },
        { text: 'Lead', value: 'lead' },
        { text: 'Customer', value: 'customer' },
        { text: 'Supplier', value: 'supplier' }
      ]
    },
    $_percentageValidation () {
      return [
        v => v ? (v >= 0 && v <= 100) || this.$t('message.common.ndt_percentage_error') : true
      ]
    },
    $_lengthValidation () {
      return [v => v ? (v.length <= 10) || this.$t('message.common.lengthError') : true]
    },
    $_sharepointCharacters () {
      return [
        (v) => v ? /^[^"*:<>?/\\|.]+$/.test(v) || this.$t('message.common.sharepointCharacters') : true
      ]
    }
  },
  methods: {
    $_maxFileSizeValidation (maxSize, files) {
      let isFileSizeExceeded = false
      if (files && files.length > 0) {
        const Newfile = [...files]
        Newfile.forEach(file => {
          const fileSize = (file.size / (1024 * 1024)).toFixed(2)
          if (fileSize > maxSize) {
            isFileSizeExceeded = true
            this.isShowDocumentErrors = true
          } else this.isShowDocumentErrors = false
        })
      } else this.isShowDocumentErrors = false
      return [
        isFileSizeExceeded ? this.$t('message.customModule.fileSizeLimitMsg', { maxSize }) : true
      ]
    },
    handleAlert (color, message) {
      this.$store.commit('hideLoader')
      this.$root.$emit('snackbar', {
        snackbar: true,
        color: color,
        message: message,
        isException: true
      })
    },
    handleServerSideError (error) {
      this.$store.commit('hideLoader')
      switch (error.response && error.response.status) {
        case 404:
          this.handleAlert('error', error.response.data.message)
          if (this.$cookie.get('WelditPwa_host-ref')) this.$router.push('dashboard')
          else this.$router.push('/login')
          break
        case 422:
          this.handleAlert('error', Object.values(error.response.data.errors)[0][0])
          break
        case 401:
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.authFailed' })
          this.$store.dispatch('logout')
          this.$router.push('/login').catch(() => {})
          break
        default:
          this.handleAlert('error', this.$t('message.common.wentWrongMsg'))
          break
      }
    }
  }
}
