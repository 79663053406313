import moment from 'moment'
export default {
  install (Vue, options) {
    Vue.prototype.$formatter = {
      replaceDotWithComma (value) {
        if (value === null || value === undefined || value === '') return ''
        return parseFloat(value).toFixed(2).toString().replace('.', ',')
      },
      replaceDotWithCommaWOFixed (value) {
        if (value === null || value === undefined || value === '') return ''
        return value.toString().replace('.', ',')
      },
      replaceCommaWithDot (value) {
        if (!value) return null
        return value.toString().replace(',', '.')
      },
      replaceCommaWithDotCal (value) {
        if (!value) return 0
        return parseFloat(value.toString().replace(',', '.'))
      },
      fixedNumbers (value) {
        if (value === null || value === undefined || value === '') return 0
        return +parseFloat(value).toFixed(2)
      },
      absoluteNumber (value) {
        if (value === null || value === undefined || value === '') return '0'
        return value.toString().indexOf('.') === -1 ? parseFloat(value).toString() : parseFloat(value).toFixed(2).toString().replace('.', ',')
      },
      fixedNumbersWithComma (value) {
        if (value === null || value === undefined || value === '') return 0
        return +parseFloat(value).toFixed(2).replace('.', ',')
      },
      cloneVariable (data) {
        if (!data) return null
        return JSON.parse(JSON.stringify(data))
      },
      filterDate (date) {
        if (!date) return null
        return moment(date, 'DD.MM.YYYY').isValid() ? moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY') : null
      },
      formatDate (date, fromFormat, toFormat) {
        if (!date) return null
        return moment(date, fromFormat).isValid() ? moment(date, fromFormat).format(toFormat) : null
      },
      isNullOrEmpty (val) {
        return val === undefined || val === null || val === ''
      },
      isNull (val) {
        return val === null
      },
      isEmptyObject (obj) {
        return Object.entries(obj).length === 0 && obj.constructor === Object
      },
      isArrayHasData (array) {
        if (array && array.length > 0) return true
        else return false
      },
      getRandomNumberInBW (min, max) {
        return parseInt(Math.random() * (max - min) + min)
      },
      debounce (func, delay) {
        let debounceTimer
        return function () {
          const context = this
          const args = arguments
          clearTimeout(debounceTimer)
          debounceTimer = setTimeout(() => func.apply(context, args), delay)
        }
      },
      debounceWithPromiseReturn (inner, ms = 0) {
        let timer = null
        let resolves = []

        return function (...args) {
          // Run the function after a certain amount of time
          clearTimeout(timer)
          timer = setTimeout(() => {
            // Get the result of the inner function, then apply it to the resolve function of
            // each promise that has been created since the last time the inner function was run
            const result = inner(...args)
            resolves.forEach(r => r(result))
            resolves = []
          }, ms)

          return new Promise(resolve => resolves.push(resolve))
        }
      },
      // _getSumBy([4, 2, 8, 6]) // 20
      getSum (arr) {
        return [...arr].reduce((acc, val) => acc + val, 0)
      },
      // _getSumBy([{ n: 4 }, { n: 2 }, { n: 8 }, { n: 6 }], x => x.n) // 20
      getSumBy (arr, fn) {
        const self = this
        if (this.isArrayHasData(arr)) {
          return arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce(function (acc, val) {
            return self.replaceCommaWithDotCal(acc) + self.replaceCommaWithDotCal(val)
          }, null)
        } else return null
      },
      getCurrentDate () {
        return moment(new Date()).format('DD.MM.YYYY')
      },
      groupBy (array, key) {
        return array.reduce(function (total, item) {
          total[item[key]] = total[item[key]] || []
          total[item[key]].push(item)
          return total
        }, {})
      },
      // _findValueInArray([{ n: 4, b: 10 }, { n: 2, b: 11 }], x => x.b === 10) // { n: 4, b: 10 }
      findValueInArray (array, condition) {
        const getObj = array.find(condition)
        if (getObj) return getObj
        else return null
      },
      // _getSingleValueFromObject([{ n: 4, b: 10 }, { n: 2, b: 11 }], x => x.b) // [10, 11]
      mapDataInArray (array, condition) {
        const getObj = array.map(condition)
        if (getObj) return getObj
        else return null
      },
      /* {
          number: { list:['qty', 'price'], format: 'replaceCommaWithDot' },
          date: { list:['start', 'end'], from: 'DD.MM.YYYY', to: 'YYYY.MM.DD'},
          status: { list:['status'], text: '', value: '$_ccsheetStatus' },
          merge: { list:['firstname', 'lastname'], property: 'username' }
      */
      formatModel (model, data) {
        model = JSON.parse(JSON.stringify(model))
        const keys = Object.keys(data)
        keys.forEach(key => {
          switch (key) {
            case 'number': {
              const formatObj = data.number
              formatObj.list.forEach(item => {
                model[item] = this[formatObj.format](model[item])
              })
              break
            }
            case 'date': {
              const formatObj = data.date
              formatObj.list.forEach(item => {
                const dateObj = this.formatDate(model[item], formatObj.from, formatObj.to)
                model[item] = dateObj
              })
              break
            }
            case 'status': {
              const formatObj = data.status
              formatObj.list.forEach(item => {
                const statusArray = this[formatObj.value]
                statusArray.map(function (x) {
                  if (x.value === model[item]) model[item] = { text: x.text, value: model[item] }
                })
              })
              break
            }
            default:
              break
          }
        })
        return model
      },
      // Array of $_formatModel FUNCTION
      formatListModel (listOfModel, data) {
        if (this.isArrayHasData(listOfModel)) {
          const result = []
          listOfModel.forEach(model => {
            result.push(this.formatModel(model, data))
          })
          return result
        } else return []
      },
      sortByType (item1, item2) {
        if (typeof (item1) === 'string' && typeof (item2) === 'string') {
          if (/^\d+$/.test(item2) && /^\d+$/.test(item1)) {
            return item1.localeCompare(item2, undefined, {
              numeric: true
            })
          }
          return item1.localeCompare(item2)
        } else {
          return item1 < item2 ? -1 : 1
        }
      },
      customSorting (items, index, isDesc, dateColumnArray, type) {
        items.sort((a, b) => {
          if (dateColumnArray.includes(index)) {
            const date1 = a[index]
            const date2 = b[index]
            if (!isDesc) {
              return this.sortWithDates(date1, date2, type)
            } else {
              return this.sortWithDates(date2, date1, type)
            }
          } else {
            if (!a[index]) a[index] = ''
            if (!b[index]) b[index] = ''
            if (!isDesc) {
              return this.sortByType(a[index], b[index])
            } else {
              return this.sortByType(b[index], a[index])
            }
          }
        })
        return items
      },
      sortWithDates (date1, date2, type) {
        if (date1 === '' || date1 === null) return -1
        if (date2 === '' || date2 === null) return 1
        var dateA = moment(date1, type)
        var dateB = moment(date2, type)
        return dateA - dateB
      },
      // Set Foreground color based on the background color
      foreGroundColor (colorValue) {
        const rgbCode = colorValue ? this.hexToRgbA(colorValue) : ''
        const color = rgbCode ? rgbCode.toString().split('(')[1] : ''
        const resultOnColor = color.split(',', 3)
        const R = resultOnColor[0]
        const G = resultOnColor[1]
        const B = resultOnColor[2] ? resultOnColor[2].toString().replace(')', '') : 0
        const C = [R / 255, G / 255, B / 255]
        for (var i = 0; i < C.length; ++i) {
          if (C[i] <= 0.03928) {
            C[i] = C[i] / 12.92
          } else {
            C[i] = Math.pow((C[i] + 0.055) / 1.055, 2.4)
          }
        }
        const L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2]
        if (L > 0.179) {
          return 'black'
        } else {
          return 'white'
        }
      },
      hexToRgbA (hex) {
        hex = hex.slice(0, -2)
        var c
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split('')
          if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
          }
          c = '0x' + c.join('')
          return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)'
        }
        return null
      },
      stringToProperCase (value) {
        return value.toLowerCase().split('_').map(function (word) {
          return (word.charAt(0).toUpperCase() + word.slice(1))
        }).join('_')
      },
      getHumanzieTime (time) {
        return moment(time, 'DD.MM.YYYY HH:mm:ss').isValid() ? moment(time, 'DD.MM.YYYY HH:mm:ss').fromNow() : null
      },
      mimeTypeOfDocument (type) {
        let image = ''
        switch (type) {
          case 'image/png':
          case 'image/jpeg':
          case 'image/gif':
          case 'image/svg+xml':
            image = { icon: 'mdi-file-image', color: 'grey' }
            break
          case 'application/pdf':
            image = { icon: 'mdi-file-pdf', color: 'error' }
            break
          case 'text/html':
            image = { icon: 'mdi-language-html5', color: '#f16428' }
            break
          case 'video/mp4':
            image = { icon: 'mdi-file-video', color: 'grey' }
            break
          case 'audio/mpeg':
            image = { icon: 'mdi-audiobook', color: 'grey' }
            break
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
            image = { icon: 'mdi-file-word', color: 'info' }
            break
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
            image = { icon: 'mdi-file-excel', color: 'green' }
            break
          case 'application/vnd.ms-powerpoint':
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          case 'application/vnd.openxmlformats-officedocument.presentationml.template':
          case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
            image = { icon: 'mdi-file-powerpoint', color: 'error' }
            break
          case 'application/x-rar-compressed':
          case 'application/octet-stream':
          case 'application/zip':
          case 'application/x-zip-compressed':
          case 'multipart/x-zip':
            image = { icon: 'mdi-zip-box', color: 'grey' }
            break
          case 'folder':
            image = { icon: 'mdi-folder', color: '#578ebe' }
            break
          default:
            image = { icon: 'mdi-file-cloud', color: 'grey' }
            break
        }
        return image
      },
      // Permission Handling
      permissionSetting (listOfModules, moduleId, userDetails) {
        const modules = this.cloneVariable(listOfModules)
        const result = modules.find(x => x.id === moduleId)
        let hasManagePermission = false
        if (userDetails.id === '-100') {
          hasManagePermission = true
        } else {
          if (result && result.permissions && result.permissions.length > 0) {
            userDetails.groups.forEach(element => {
              const found = result.permissions.find(x => x.group_id === element)
              if (found && found.access_level === 'manage') hasManagePermission = true
              else hasManagePermission = false
            })
          }
        }
        return hasManagePermission
      },
      getMilliseconds (hours) {
        if (hours) {
          return (+hours * (60000 * 60))
        }
      },
      getFileName (path) {
        return path.match(/[-_\w]+[.][\w]+$/i)[0]
      },
      getNearestValueFromNumbers (value, arrayOfNumbers) { // value - number, arrayOfNumbers - list of numbers
        return arrayOfNumbers.reduce(function (prev, curr) {
          return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev)
        })
      },
      // Note: these two below methods will work when 'vue-cryptojs' package is installed
      encryptString (plainText) {
        return Vue.CryptoJS.AES.encrypt(plainText, '2020ModulesPermissionKey@TimerApp').toString()
      },
      decryptString (cipherText) {
        var bytes = Vue.CryptoJS.AES.decrypt(cipherText, '2020ModulesPermissionKey@TimerApp')
        return bytes.toString(Vue.CryptoJS.enc.Utf8)
      },
      // <---------
      objectListToArrayList (list) { // {1: {…}, 2: {…}} => [{…}, {…}]
        const arrayList = Object.keys(list).map((key) => {
          return list[key]
        })
        return arrayList
      },
      removeInvalidFromObject (obj) { // method removes undefined and null properties, inner object and arrays will also removed
        const newObj = {}
        Object.keys(obj).forEach(key => {
          if (obj[key] !== null && obj[key] !== undefined && typeof obj[key] !== 'object') newObj[key] = obj[key]
        })
        return newObj
      },
      removeInvalidFromArrayObject (list) { // iterate over the obj to get removeInvalidFromObject
        if (this.isArrayHasData(list)) {
          const result = []
          list.forEach(model => {
            result.push(this.removeInvalidFromObject(model))
          })
          return result
        } else return []
      },
      findWidthPercent (gridCols) {
        let percentNo = 5
        if (!isNaN(gridCols)) {
          percentNo = (100 / gridCols).toFixed(3).slice(0, -1)
        }
        return percentNo
      },
      findWidthPixels () {
        return 200
        /* let percentNo = 5
        if (!isNaN(gridCols)) {
          percentNo = (100 / gridCols).toFixed(3).slice(0, -1)
        }
        return percentNo */
      },
      // Encode the String
      $_encodeStringTobase64 (string) {
        if (string) {
          var encodedString = btoa(string)
          return encodedString
        }
      },
      // Decode the String
      $_decodeStringTobase64 (string) {
        if (string) {
          var decodedString = atob(string)
          return decodedString
        }
      },
      $_setSignaturePadHeight (item) {
        let height = 250
        height = [2, 1].includes(item.columns) ? 50 : item.columns * 21
        return height
      }
    }
  }
}
