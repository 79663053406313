import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#578ebe', /* da4949 */
        secondary: '#578ebe', /* da4949 */
        accent: '#737070',
        error: '#ff5252'
      }
    }
  }
})
