export const en = {
  message: {
    layout: {
      login: 'Log in',
      ortext: 'OR',
      logout: 'Logout',
      rfidsignin: 'Sign In using RFID',
      SignInpassword: 'Sign in with Password',
      ScanRFID: 'Scan your RFID Tag',
      dashboard: 'Dashboard',
      timer: 'Timer',
      projects: 'Projects',
      stemp: 'Stemp',
      calendar: 'Calendar',
      deviation: 'Deviation',
      home: 'Home',
      deliver: 'Deliver',
      more: 'More',
      files: 'Files',
      equipments: 'Equipments',
      checklists: 'Checklists',
      drawing: 'Drawing',
      pressureTestReport: 'Pressure Test Report',
      weldlog: 'Weld Log',
      event: 'Event',
      ndt: 'NDT',
      surfaceReport: 'Surface Report',
      technicalReport: 'Technical Report',
      matrialList: 'Matrial List',
      pwht: 'PWHT',
      pwps: 'PWPS',
      wps: 'WPS',
      warehouse: 'Warehouse',
      tasks: 'Tasks',
      orders: 'Orders',
      orderno: 'Order No',
      facilities: 'Facility',
      chemicallist: 'Chemical List',
      nbselftL: '',
      asbuilt: 'AS-Built',
      autoLogout: 'Auto Logout'
    },
    login: {
      language: 'Language',
      back: 'Back',
      userName: 'Username',
      password: 'Password',
      authFailed: 'Authentication failed !',
      host: 'Host'
    },
    common: {
      pwaNotEnabled: 'PWA is not enabled, please contact administrator',
      delete_row: 'Delete row',
      of: 'of',
      ready_for_production: 'Ready for production',
      production: 'Production',
      on_hold: 'On hold',
      finished: 'Finished',
      install: 'Install this application on your home screen for quick and easy access when you’re on the go.',
      justTab: 'Just tap',
      addToHome: 'then “Add to Home Screen”',
      english: 'English',
      norwegian: 'Norwegian',
      lithuanian: 'Lithuanian',
      polish: 'Polish',
      swedish: 'Swedish',
      save: 'Save',
      saveAndClose: 'Save & Close',
      send: 'Send',
      edit: 'Edit',
      cancel: 'Cancel',
      update: 'Update',
      delete: 'Delete',
      updateAndClose: 'Update & Close',
      close: 'Close',
      new: 'New',
      isActive: 'Is active',
      addNew: 'Add new',
      savedSuccess: 'Saved successfully!',
      addedSucess: 'Added successfully !',
      updatedSuccess: 'Updated successfully !',
      deleteSuccess: 'Deleted successfully !',
      backToList: 'Back To List',
      search: 'Search',
      yes: 'Yes',
      no: 'No',
      isDefault: 'Is Default',
      loadingMsg: 'Loading, Please wait',
      deleteConfirm: 'Delete Confirm',
      areYouSure: 'Are you sure you want to delete ?',
      validationIssue: 'Please check the required fields!',
      total: 'Total',
      noResults: 'No results found !',
      others: 'Others',
      typeHere: 'Type here...',
      position: 'Position',
      color: 'Color',
      maxCharacters: 'Max {charCount} characters',
      changedPassword: 'Password Changed Successfully',
      import: 'Import',
      note: 'Note',
      invalidEmail: 'Invalid Email',
      no_records: 'No Records Found!',
      no_data: 'No data available',
      ok: 'Ok',
      date: 'Date',
      comments: 'Comments',
      draftMsg: 'Saved as Draft',
      createProjectRole: 'Create Project Role',
      updateProjectRole: 'Update Project Role',
      module: 'Module',
      selectTemplate: 'Select Template',
      documentTemplate: 'Document Template',
      download: 'Download',
      uploadedSuccess: 'Uploaded successfully !',
      weekTotalHours: 'Week hrs',
      addWorktype: 'Add Worktype',
      updateWorktype: 'Update Worktype',
      worktype: 'Worktype',
      newUpdate: 'New Update Available!',
      refresh: 'Refresh',
      moduleName: 'Module Name',
      updatedOn: 'Updated On',
      columns: 'Columns',
      confirmCancel: 'Confirm Cancel',
      areYouSureCancel: 'Are you sure you want to cancel ?',
      not_approved_notification: 'Hi! {count} time registration(s) in week {weeknumber} is not approved',
      hourExceedNotification: 'Entered hour is exceeding the 24 hours',
      options: 'Options',
      label: 'Label',
      value: 'Value',
      confirmStatusChange: 'Confirm Status change',
      areYouSureToChangeStatus: 'Are you sure you want to change status to {statusText} ?',
      complete: 'complete',
      incomplete: 'incomplete',
      name: 'Name',
      number: 'Number',
      noPermission: "Sorry! You don't have permission to {permissionType} {module}",
      charsLimit35: 'Please enter no more than {length} characters',
      confirmAddHour: 'Shift has been completed!',
      sureToAddExceedingHour: 'Entered hour is exceeding the Working hours, Are you sure to add ?',
      warning: 'Warning',
      required: 'Required',
      timerFeatureDisabled: 'Timer feature disabled',
      locationNotSet: 'Location not set',
      type: 'Type',
      changeVideoSource: 'Change video source',
      permissionDenied: 'Camera permission is denied!, please enable it in the browser settings!',
      time: 'Time',
      orderno: 'Order No',
      ndt_percentage_error: 'NDT percentage must have 0 to 100',
      lengthError: 'The length must be less than 10',
      update_properties: 'Update Properties',
      add_properties: 'Add Properties',
      preparing_fields: 'Preparing fields...',
      from_attributs: 'FORM ATTRIBUTES',
      enter_title: 'Enter Title Of Each Columns:',
      loading_grid: 'Loading Grids...',
      from_properties: 'FORM PROPERTIES',
      dragging: 'Dragging...',
      catch_up: 'All caught up!!!',
      add_header: 'Add Header',
      add_footer: 'Add Footer',
      drop_fields: 'Drop the fields here',
      for_header: 'FOR HEADER',
      for_footer: 'FOR FOOTER',
      add_html: 'ADD HTML CONTENT',
      relate: 'Relate',
      unrelate: 'Unrelate',
      values: 'Values',
      scan: 'Scan',
      filename: 'Filename',
      reset: 'Reset',
      doYouWantContinue: 'Still do you want to continue ?',
      stempHint: 'If you choose Shift, you can start the stamp',
      hoursExeed: 'exeeding 24 hours',
      on: 'On',
      reachedHours: 'Reached 24 hours',
      invaliHours: 'Invalid Hours',
      notValidNumber: 'Not Valid Number',
      invalidName: 'Invalid name',
      filterSaveResult: 'Search enables you to see the filter you have applied and the filtered result can be seen in the report only if you save the checklist',
      seemsInvalid: 'Hours seems to be not valid!',
      shiftContinue: 'Your stemp hour continues...',
      no_fields_found: 'No checklist fields found',
      sharePoint: 'Sharepoint might have incorrect credentials or configurations are missing !.',
      dragAndDropTips: 'Saving is mandatory on every drag and drop to avoid misplacing of rows',
      afterCopyFilter: 'Filter acion will be performed until you save the checklist',
      archived: 'Archived',
      datagrid_filter_help_text_1: 'Results will be filtered as same as last time filter options.',
      datagrid_filter_help_text_2: 'On adding a new row, filters will be removed and all rows will be added.',
      datagrid_filter_help_text_3: 'Filters are case insensitive, so we can use both Upper case and Lower case.',
      datagrid_filter_help_text_4: "We can't filter same column twice.",
      datagrid_filter_help_text_5: 'Drag and drop will be modified by filter result, if user save the checklist.',
      datagrid_filter_help_text_6: 'The text area filtered by the word between any part of the sentence.',
      datagrid_filter_help_text_7: "It is mandatory to fill both field and it's value to perform filter action.",
      sharepointCharacters: "This field should not contain any of these characters '*:<>?/\\|.'",
      company: 'Company'
    },
    timer: {
      stop: 'Stop',
      ga_til_time: 'Go to Hourlist',
      minutes: 'minutes',
      start: 'Start',
      place: 'Place',
      hours: 'Hours',
      activity: 'Activity',
      pause: 'Pause',
      from: 'From',
      till: 'Till',
      shift: 'Shift',
      startTime: 'Start time',
      endTime: 'End time',
      editHours: 'Edit Hour',
      approved: 'Approved',
      notApproved: 'Not Approved',
      delivered: 'Delivered',
      notDelivered: 'Not Delivered',
      year: 'Year',
      week: 'Week',
      nonBillable: 'Non Billable',
      hourlogging: 'Hourlogging',
      noHours: 'No hours found for deliver !',
      deliveredMsg: 'Delivered Successfully',
      addHours: 'Add Hour',
      deliverHours: 'Deliver hours',
      hoursExceeding: 'Timer Exceeded 8 hours',
      cancelTimer: 'Cancel Timer',
      uploadSuccess: 'Upload Success',
      picture: 'Take a Picture',
      imageNeeded: 'Please upload a picture before you stop the timer!',
      nonbillableworktype: 'Vacation / Absence type'
    },
    projects: {
      startDate: 'Start date',
      endDate: 'End date',
      all: 'All',
      allActive: 'All Active',
      myAll: 'My All',
      myActive: 'My Active',
      project: 'Project',
      customer: 'Customer',
      status: 'Status',
      noActiveProjects: 'No Active Projects!',
      selectProject: 'Select Project',
      projectMaterialDisabled: 'Materials disabled for this project',
      drawingGroup: 'Drawing Group'
    },
    calendar: {
      today: 'Today',
      month: 'Month',
      day: 'Day',
      weekNotExists: "Week doesn't exist",
      yearValid: 'Must be four digits',
      valid: 'Must be valid',
      yearTooLessValidation: 'Year must be greatethan 1920',
      weekLock: 'Selected dates are in the locked week',
      dayLocked: 'Selected date is in the locked week',
      otherHourEntries: 'Other hour entries'
    },
    shifts: {
      shift: 'My shifts',
      documents: 'Documents:',
      not_found: 'No description and url found.',
      notValidPausedTime: 'Pause time is greater than shift time',
      continue: 'Continue',
      clear: 'Clear'
    },
    deviation: {
      deviations: 'Deviation',
      register_deviation: 'Register Deviation',
      view_deviation: 'View Deviation',
      high: 'High',
      medium: 'Medium',
      low: 'Low',
      closed: 'Closed',
      registered: 'Registered',
      process: 'Process',
      actions: 'Actions',
      title: 'Title',
      reported_by: 'Reported By',
      location: 'Location',
      reason: 'Reason',
      document: 'Document',
      category: 'Category',
      estimate_cost: 'Estimate Cost',
      severity: 'Severity',
      deviation_status: 'Deviation Status',
      deadline: 'Deadline',
      description: 'Description',
      responsible: 'Responsible',
      copyto: 'Copy To',
      upload_files: 'Upload',
      required_message: 'This field is required',
      consequences: 'Consequence',
      changeRequest: 'Change Request',
      hse: 'HSE',
      deviation_number: 'Deviation Number',
      underlying_reason: 'Underlying Reason',
      corrective_actions: 'Corrective Actions',
      effect_of_measures: 'Effect Of Measures'
    },
    checklist: {
      title: 'Checklist',
      updateChecklist: 'Update checklist',
      pickCheckList: 'Select a checklist',
      addCheckList: 'Add checklist',
      hmsKs: 'HMS/KS',
      templateName: 'Template name',
      checklist_recipients: 'Email recipient',
      checklist_recipient_hint: 'Enter email using comma(,) separated.',
      projectnr: 'PO Ranked',
      projectname: 'Projectname',
      aonr: 'AO Ranked',
      workordername: 'Work order name',
      customer: 'Customer',
      customercontact: 'Customer contact',
      projectleader: 'Project Leader',
      importCSV: 'Import CSV',
      CSVFileInfo: 'Here you can import data from an csv file into a checklist.',
      removeRow: 'Remove 1. row if it has headers.',
      sameOrederColumn: 'Make sure the columns are in the same order as u have defined it in the checklist.',
      semicolonDelimiter: 'Please use semicolon as csv delimiter.',
      withoutImport: 'Without Import',
      addCSVFile: 'Add CSV File',
      report_header: 'Report header',
      copy_confirm: 'Do you want to copy the checklist?',
      filter: 'Filter',
      apply: 'Apply'
    },
    equipments: {
      equipment: 'Equipment',
      addEquipment: 'Add Equipment',
      updateEquipment: 'Update Equipment',
      viewEquipment: 'View Equipment',
      active: 'Active',
      quarantine: 'Quarantine',
      discarded: 'Discarded',
      warrantydate: 'Warranty Date',
      dateofPurchase: 'Date of Purchase',
      vendor: 'Vendor',
      modelNumber: 'Model Number',
      product: 'Product',
      serialNumber: 'Serial Number',
      capacity: 'Capacity',
      groups: 'Groups',
      plannedCalibration: 'Planned Calibration',
      scrapDate: 'Scrap Date',
      equipmentNumber: 'Equipment Number',
      placement: 'Placement',
      notifyByEmail: 'Notify by Email',
      mustAllocateToProjectOrLocation: 'Must allocate the equipment to either Project or Location.',
      confirm: 'Are you sure?'
    },
    customModule: {
      modules: 'Modules',
      customModule: 'Custom module',
      customModules: 'Custom modules',
      uploadDocument: 'Upload Document',
      isCompleted: 'Is Completed',
      add: 'Add',
      urlNotFound: 'Download url not found',
      completed: 'Completed',
      notCompleted: 'Not completed',
      fileSizeLimitMsg: 'File Size limit exceeded for one or more files (Max.Limit {maxSize}MB).'
    },
    moduleReports: {
      title: 'Module reports',
      chartyAxis: 'Number Of Records (per module)'
    },
    projectFeature: {
      drawingNumber: 'Drawing nr.',
      poRanked: 'PO Ranked',
      operator: 'Operator',
      reportNumber: 'Report nr.',
      uploadNdtDocument: 'Upload NDT Document',
      aoRanked: 'AO Ranked',
      NDTSpecifications: 'NDT Specifications',
      NDTScope: 'NDT Scope',
      filterMaterials: 'Filter Materials',
      chargedLotNumber: 'Charde/Lot nr.',
      selectFiles: 'Select files',
      createdOn: 'Created On',
      weldingID: 'Welding ID',
      weldNumber: 'Weld nr.',
      wpsNumber: 'WPS nr.',
      wpsAsComma: 'Enter WPS no. As comma separated.',
      weldEnd: 'Weld End',
      vtSignDate: 'VT Sign./Date',
      rtSignDate: 'RT Sign./Date',
      utSignDate: 'UT Sign./Date',
      mtPtSignDate: 'MT/PT Sign./Date',
      createdDate: 'Created Date',
      tegNr: 'Teg nr.',
      posNr: 'Pos nr.',
      batchNr: 'Batch nr.',
      heatNr: 'Heat nr.',
      onStock: 'On Stock',
      out: 'Out',
      recordNumber: 'Record Number',
      chartSpeed: 'Chart Speed',
      chartNumber: 'Chart Number',
      unrestrictedTo: 'Unrestricted To',
      restrictedRateOfRise: 'Restricted Rate of Rise',
      holdTemperatureRequired: 'Hold Temperature Required',
      holdTemperatureActual: 'Hold Temperature Actual',
      holdTimeActual: 'Hold Time Actual',
      holdTimeRequired: 'Hold Time Required',
      restrictedRateDown: 'Restricted Rate Down',
      unrestrictedRateOfFall: 'Unrestricted Rate of Fall',
      weldDate: 'Weld date',
      performedBy: 'Performed By',
      approvedBy: 'Approved By',
      approvedDate: 'Approved Date',
      weldLogDate: 'Weld date',
      productNumber: 'Product number',
      weldLength: 'Welding length(mm)',
      designation_1: 'Part 1 Designation',
      designation_2: 'Part 2 Designation',
      thickness_1: 'Thickness 1(mm)',
      thickness_2: 'Thickness 2(mm)',
      weld_diameter: 'Welding diameter(mm)',
      notAccepted: 'Not accepted',
      ndtStatus: 'Ndt status',
      isDiameter: 'Is diameter',
      ndt: '%NDT',
      weldingCompound: 'Welding Compound',
      batch_lot: 'Batc/lot',
      choose: 'Choose',
      pipe_description: 'Pipe description'
    },
    whsDashboard: {
      scanText: 'Scan order number or barcode',
      customerOrder: 'Customer orders',
      receiveSupplierOrder: 'Receive supplier orders',
      adjustmentOrders: 'Adjustment orders',
      transferOrders: 'Transfer orders',
      cycleCount: 'Cycle count',
      product: 'Product',
      supplierOrder: 'Supplier order',
      returnOrder: 'Return order'
    },
    ccsheet: {
      warehouse: 'Warehouse',
      created: 'Created',
      counted: 'Counted',
      count: 'Count',
      reCount: 'Recount',
      closeComplete: 'Close & Complete',
      unit: 'Unit',
      stock: 'Stock',
      scanLocation: 'Scan or enter location',
      nextLoc: 'Next Loc',
      info: 'Message!',
      continueCount: 'Continue counting',
      clearAndRecount: 'Clear & recount',
      completeRecount: 'Complete recount',
      ccsheetComplete: 'CCSheet will be completed. Continue?',
      noSheetFound: 'No CCSheet Found!',
      noProductOnLocation: 'No product is found on this location!',
      stockTake: 'Stock take for the chosen location has already been performed. Do you want to continue counting in this location or clear data and perform new stock take?',
      ccsheetAddProduct: 'Product is not added to this ccsheet would like to add ?'
    },
    product: {
      supplierartno: 'Supplier article number',
      supplier: 'Supplier Name',
      barcode: 'Barcode',
      noProductFound: 'No Product Found!',
      minQty: 'Min Qty',
      orderQty: 'Order Qty',
      qty: 'Qty',
      batch: 'Batch',
      scanLoc: 'Select Loc.',
      noLocFound: 'No Location Found!'
    },
    order: {
      products: 'Products',
      avail: 'Avail',
      ordered: 'Ord.',
      received: 'Rec.',
      pickedQty: 'Pic.',
      pickOrder: 'Pick order',
      pickProducts: 'Pick products',
      delivery: 'Del',
      rest: 'Rest',
      complete: 'Complete',
      pick: 'Pick',
      scanProduct: 'Scan barcode of a product',
      scanBatch: 'Scan batchnumber',
      picked: 'Picked',
      toPick: 'To pick',
      location: 'Loc',
      pickLocation: 'Select location',
      qtyExceeds: 'Qty Exceeds',
      noOrderFound: 'No order found!',
      notValidBatch: 'Batchnumber is not valid!',
      receiveOrder: 'Receive order',
      receive: 'Receive',
      batchExists: 'Batch number already exists!',
      receiveWarning: 'Would you like to add {qty} pcs of {product} to stock?',
      completedSuccess: 'Completed successfully!',
      addFreight: 'Do you want to add freight?',
      cost: 'Cost',
      price: 'Price',
      orderedQtyHigh: 'Qty received is higher than ordered, would you like to proceed?',
      collect: 'Collect',
      receiving: 'Receiving',
      availableToPick: 'Avail qty(s) to receive',
      adjust: 'Adjust',
      orderComplete: 'Order will be completed. Continue?'
    },
    tasks: {
      forYour: 'For your',
      information: 'Information',
      acknowledge: 'Acknowledge',
      review: 'Review',
      approval: 'Approval',
      subject: 'Subject',
      dueDate: 'Due date',
      approve: 'Approve',
      disapprove: 'Disapprove',
      keep: 'Keep',
      remove: 'Remove'
    },
    mixed: {
      scanSerialNumber: 'Scan serial number',
      noEquipmentFound: 'No equipment found',
      sharedUrl: 'Shared url',
      checklistfiles: 'Checklist Files',
      attachments: 'Attachments',
      signature: 'Signature',
      nochecklists: 'No checklists',
      checklistName: 'Checklist Name',
      noOfDocuments: 'No.Of.Documents',
      documentTitle: 'Document title',
      qrCode: 'QR Code',
      qrQty: 'QR Quantity',
      scanQRCode: 'Scan QR Code',
      irscanner: 'Ir Scanner',
      attachmentsBelow: 'Find Attachments below',
      noChemicalFound: 'No Chemical list Found'
    },
    chemicallist: {
      chemical: 'Chemical',
      provider: 'Provider',
      repository: 'Repository',
      last_revised: 'Last revised',
      viewChemical: 'Chemical',
      chemicalName: 'Chemical name'
    }
  }
}
